import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { Dialog } from 'components/ui';

import { DataProtectionData } from '../DataProtectionData';
import { PersonalData } from '../PersonalData';

import { useFetch } from 'func';
import { User } from 'types';
import { useFormik } from 'formik';
import { USERS_URL } from 'const';

type Props = {
  id: number;
  open: boolean;
  onClose: () => void;
  noDataProtection?: boolean;
  public?: boolean;
};

export function ProfileDialog(props: Readonly<Props>) {
  const { data } = useFetch<User>(`${USERS_URL}/${props.id}`);
  const [user, setUser] = useState<User>();

  const formik = useFormik({
    initialValues: {
      id: user?.id,
      profile: {
        nickname: user?.profile?.nickname,
        pronouns: user?.profile?.pronouns,
        socialMediaAccounts: {
          TWITTER: user?.profile?.socialMediaAccounts?.TWITTER,
          SPOTIFY: user?.profile?.socialMediaAccounts?.SPOTIFY,
          PINTEREST: user?.profile?.socialMediaAccounts?.PINTEREST,
          INSTAGRAM: user?.profile?.socialMediaAccounts?.INSTAGRAM,
        },
        allergies: user?.profile?.allergies,
        phobiae: user?.profile?.phobiae,
        eatingHabits: user?.profile?.eatingHabits,
        dataVisibility: user?.profile?.dataVisibility || '',
        imageRights: user?.profile?.imageRights || '',
      },
      dateOfBirth: user?.dateOfBirth,
      email: user?.email,
      firstname: user?.firstname,
      lastname: user?.lastname,
      streetName: user?.streetName,
      streetNumber: user?.streetNumber,
      addressOptional: user?.addressOptional,
      zipCode: user?.zipCode,
      city: user?.city,
      readonly: true,
    },
    enableReinitialize: true,
    onSubmit: null,
  });

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  return (
    <Dialog
      open={props.open}
      sx={{
        '& .MuiPaper-root': {
          bgcolor: '#1e1e1e',
          marginBottom: '0',
        },
      }}
      onClose={props.onClose}
      title={user?.profile?.nickname}
      content={
        <Box
          className='content'
          sx={{ '& .MuiSvgIcon-root': { color: '#fff' } }}
        >
          <Grid container spacing={2} sx={{ padding: '20px' }}>
            <PersonalData
              formik={formik}
              width={!props.noDataProtection ? 6 : 12}
              public={props.public}
            />
            {!props.noDataProtection && <DataProtectionData formik={formik} />}
          </Grid>
        </Box>
      }
    />
  );
}
