import { t } from 'i18next';
import { Box, CircularProgress, Grid } from '@mui/material';

import { Button, Dialog, SelectField, TextField } from 'components/ui';

import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { CHARACTERS_URL, HTTP_METHOD, THESTRAL_URL } from 'const';
import { useState } from 'react';
import { Character } from 'types';

type Props = {
  open: boolean,
  onClose: () => void,
  triggerRefetch: () => void
}

export function NewNpcDialog(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const types = useFetch<string[]>(`${THESTRAL_URL}/npc-types`);
  const [npc, setNpc] = useState<Character>();

  const onSave = () => {
    setLoading(true);
    const body = JSON.stringify(npc);
    fetchFromBackend(`${CHARACTERS_URL}/npcs`, {
      method: HTTP_METHOD.POST,
      body
    }).then((response) => {
          const severity = response.status < 400 ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          if (response.ok) props.triggerRefetch();

          showSnackbar(`${t('Components.Admin.Character.NpcSave')} ${result}`, severity);
          props.onClose();
    }).finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      caption={t('Components.Admin.Character.NewNpcCaption')}
      content={
        loading ? 
            <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <CircularProgress />
            </Box>
          :
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
                <TextField
                  label={t('Generic.Name')} 
                  onChange={(event) => setNpc({...npc, name: event.target.value})}
                />
              </Grid>
              <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
                <SelectField
                  label={t('Components.NonPlayerCharacters.Type')}
                  id='NpcType'
                  value={npc?.npcType ? [npc?.npcType] : null}
                  i18nKey='Enum.NpcType'
                  list={types.data}
                  onChange={(event) => setNpc({...npc, npcType: event.target.value})}
                />
              </Grid>
            </Grid>
      }
      actions={
        <Button 
          text={t('Generic.Save')}
          onClick={onSave}
          color='success'
          disabled={loading}
        />
      }
      title={t('Components.Admin.Character.NewNpc')}
    />
  );
}
