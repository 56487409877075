import { ReactNode } from 'react';
import {Link, ListItem, ListItemIcon, ListItemButton} from '@mui/material';
import {DoubleArrow} from '@mui/icons-material';

import { CustomLink } from 'types';

type Props = {
    item: CustomLink
}

export function RulesListItemLink(props: Readonly<Props>): ReactNode {
  return (
    <ListItem
      sx={{
        padding: '0 16px 0 16px',
        '& .MuiLink-root': {
          fontSize: '20px',
          fontFamily: 'Neuton',
          color: 'primary.main',
        }
    }}>
      <ListItemIcon sx={{color: '#ffffff', marginRight: '-20px'}}><DoubleArrow /></ListItemIcon>
      <ListItemButton
        target={!props.item.href.startsWith('#') && '_blank'}
        component={Link}
        href={props.item.href}
        sx={{
          color: 'primary.main',
          textDecoration: 'underline',
          padding: 0
        }}
      >
        {props.item.title}
      </ListItemButton>
    </ListItem>
  );
}