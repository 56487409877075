import { Chip } from '@mui/material';
import { t } from 'i18next';
import { ReactNode } from 'react';
import { DraperyCheck } from 'types';

type Props = {
  drapery: DraperyCheck
}

export function DraperyApprovalStatus(props: Readonly<Props>): ReactNode {
  const approvalStatus = () => {
    const elem = props.drapery;
    switch(elem?.status) {
      case 'APPROVED':
          return (
            <Chip color='success' label={t('Components.Drapery.State.' + elem?.status)} sx={{marginLeft: '10px', fontFamily: 'Neuton'}}/>
          );
      case 'DECLINED':
          return (
            <Chip color='error' label={t('Components.Drapery.State.' + elem?.status)} sx={{marginLeft: '10px', fontFamily: 'Neuton'}}/>
          );
    }
    return (
      <Chip color='primary' label={t('Components.Drapery.State.' + elem?.status)} sx={{marginLeft: '10px', fontFamily: 'Neuton', color: '#fff', fontStyle: 'bold'}}/>
    );
  };

  return (approvalStatus());
}