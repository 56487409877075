import { Button, Dialog } from 'components/ui';
import { t } from 'i18next';
import { Character } from 'types';
import { NonPlayerCharacterCard } from '../NonPlayerCharacterCard';
import { Trans } from 'react-i18next';
import { useFetchFromBackend, useSnackbar } from 'func';
import { CHARACTERS_URL, HTTP_METHOD, PEOPLE_URL } from 'const';
import { Box, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { PlayerCharacterCard } from '../PlayerCharacterCard';

type Props = {
  open: boolean,
  onClose: () => void,
  triggerRefetch?: () => void
  character: Character
  npc?: boolean
}

export function AdminViewDialog(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const {showSnackbar} = useSnackbar();
  const [loading, setLoading] = useState<boolean>();

  const onSaveNpc = () => {
    setLoading(true);
    const body = JSON.stringify(props.character);
    fetchFromBackend(`${CHARACTERS_URL}/npcs/${props.character?.id}`, {
      body,
      method: HTTP_METHOD.PUT
    }).then((response) => {
        if (props.character?.newPortrait) {
          saveNewPortrait();
        }
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          props.triggerRefetch();
        }

        setLoading(false);
        showSnackbar(`${t('Components.Admin.Character.NpcUpdate')} ${result}`, severity);
    }).finally(() => setLoading(false));
  };

  function saveNewPortrait() {
    const char = props.character;
    const body = new FormData();
    body.append('uploadFile', char?.newPortrait);
    const url = `${PEOPLE_URL}/${char?.id}/portrait`;

    fetchFromBackend(url, {method: HTTP_METHOD.POST, body})
      .then((response) =>  {
          const severity = response.status < 400 ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;

          if (!response.ok) {
            showSnackbar(`${t('Components.Characters.Saved')} ${result}`, severity);
          }
        }
      );
  }

  return (
    <>
    {loading ?
      <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
        <CircularProgress />
      </Box>
      :
      <Dialog
        open={props.open}
        onClose={props.onClose}
        content={props.npc ? <NonPlayerCharacterCard /> : <PlayerCharacterCard readonly />}
        actions={
          props.npc ? <Button onClick={onSaveNpc} color='success' text={<Trans i18nKey='Generic.Save' />} /> : <></>
        }
        title={`${props.npc ? t('Generic.Edit') : 'Generic.Details'} - ${props.character?.name}`}
      />
    }
    </>
  );
}
