import { ReactNode } from 'react';
import { Link } from '@mui/material';

type Props = {
  to: string,
  children?: ReactNode
  target?: string
  underline?: 'hover' | 'always' | 'none'
  color?: string
}

export function LinkText(props: Readonly<Props>) {
  return (
    <Link target={props.target} color={props.color ?? 'primary.main'} href={props.to || '#'} underline={props.underline}>
      {props.children}
    </Link>
  );
}
