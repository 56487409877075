import { CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Button, Dialog } from 'components/ui';

import { useFetchFromBackend, useSnackbar } from 'func';
import { HTTP_METHOD } from 'const';
import { useState } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  i18nBase: string;
  endpoint: string;
  triggerRefetch: () => void;
};

export function DeleteDialog(props: Readonly<Props>) {
  const [loading, setLoading] = useState<boolean>(false);
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();

  const onConfirmDelete = () => {
    setLoading(true);
    fetchFromBackend(props.endpoint, {
      method: HTTP_METHOD.DELETE,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          props.triggerRefetch();
        }

        setLoading(false);
        showSnackbar(`${t(props.i18nBase + '.Deletion')} ${result}`, severity);
        props.onClose();
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={t(props.i18nBase + '.Deletion')}
      width='100%'
      content={
        loading ? (
          <CircularProgress />
        ) : (
          <Typography
            sx={{ textAlign: 'center' }}
            variant='caption'
            component='div'
          >
            {t(props.i18nBase + '.Delete')}
          </Typography>
        )
      }
      actions={
        loading ? (
          <></>
        ) : (
          <Button
            color='success'
            onClick={onConfirmDelete}
            text={<Trans i18nKey='Generic.Delete' />}
          />
        )
      }
    />
  );
}
