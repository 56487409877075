import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Box } from '@mui/material';

import { Background } from './Background';
import { Navigation } from './Navigation';
import { Footer } from './Footer';

import { SnackbarProvider } from 'components/thestral';

const publicLocs = [
  '/rules/code-of-conduct',
  '/rules/world-building',
  '/register',
  '/login',
  '/signup',
  '/waiting-list',
  '/privacy',
  '/imprint',
];

export function Layout() {
  const auth = useAuth();
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    if (
      !publicLocs.includes(location.pathname) &&
      !auth.isAuthenticated &&
      !auth.isLoading
    ) {
      nav('/login');
    }
  }, [auth.isLoading, auth.isAuthenticated]);

  return (
    <>
      <Background />

      <SnackbarProvider>
        {auth.isAuthenticated && (
          <Box sx={{ flexGrow: 1, minHeight: '64px' }} id='navigation'>
            <Navigation />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',

            '@media(min-width: 960px)': {
              maxWidth: '900px',
            },
            '@media(min-width: 1264px)': {
              maxWidth: '1185px',
            },
            '@media(min-width: 1904px)': {
              maxWidth: '1785px',
              width: '1360px',
            },
            margin: '0 auto 65px auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          id='main'
        >
          <Outlet />
        </Box>
        <Footer />
      </SnackbarProvider>
    </>
  );
}
