import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Box, Grid } from '@mui/material';

import { Dialog } from 'components/ui';
import { ThestralDataGrid } from 'components/thestral';

import { WAITINGLIST_URL } from 'const';
import { useFetch } from 'func';
import { WaitingListPerson } from 'types';

type Props = {
  open: boolean,
  onClose: () => void
  title: string
}

export function WaitingListDialog(props: Readonly<Props>) {
  const { data } = useFetch<WaitingListPerson[]>(WAITINGLIST_URL);  
  const [waitingList, setWaitingList] = useState<WaitingListPerson[]>();

  useEffect(() => {
    if (data) {
      setWaitingList(data);
    }
  }, [props.open, data]);
  return (
      <Dialog 
        open={props.open}
        onClose={props.onClose} 
        title={props.title}
        width='600px'
        content={
          <Box className='content' sx={{marginTop: '20px'}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ThestralDataGrid 
                  hideSearch
                  hideFooter
                  hideFooterPagination
                  sx={{
                    maxWidth: '600px',
                    minWidth: '300px'
                  }}
                  rows={waitingList ?? []}          
                  columns={[
                    {field: 'nickname', headerName: t('Generic.Nickname'), flex: 1},
                    {field: 'pronouns', headerName: t('Generic.Pronouns'), flex: 1},
                    {field: 'email', headerName: t('Generic.Email'), flex: 1},
                  ]} 
                />
              </Grid>
            </Grid>
          </Box>
        }
      />
  );
}
