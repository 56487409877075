import { SyntheticEvent, useContext, ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { AccordionContext } from 'contexts';

type Props = {
  title: string,
  content: ReactNode,
  refKey: string
}

export function RulesAccordion(props: Readonly<Props>): ReactNode {
  const {expanded, setExpanded} = useContext(AccordionContext);

  const handleChange =
    (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? props.refKey : false);
  };

  return (
    <Accordion expanded={expanded === props.refKey} onChange={handleChange} sx={{backgroundColor: '#1e1e1e'}}>
      <AccordionSummary expandIcon={<ExpandMore sx={{color: '#ffffff'}}/>}>
        <Typography sx={{color: 'primary.main', fontWeight: 'bold', fontSize: '1.17em', fontFamily: 'Neuton'}}>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.content}
      </AccordionDetails>
    </Accordion>
  );
}