import { Box, CircularProgress, Tab } from '@mui/material';
import { t } from 'i18next';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { Dialog } from 'components/ui';
import { Participations } from 'components/thestral';

import { useFetch } from 'func';
import { useEffect, useState } from 'react';
import { Event, Participant } from 'types';
import { EVENTS_URL } from 'const';

type Props = {
  open: boolean;
  onClose: () => void;
  event: Event;
};

export function ParticipationDialog(props: Readonly<Props>) {
  const event = props.event;
  const { data, triggerRefetch } = useFetch<Participant[]>(
    `${EVENTS_URL}/${event?.id}/participants`
  );
  const [participants, setParticipants] = useState<Participant[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('1');

  const handleTab = (event, value) => {
    setValue(value);
  };

  useEffect(() => {
    setLoading(true);
    triggerRefetch();
  }, [event]);

  useEffect(() => {
    if (data) {
      setParticipants(data);
      setLoading(false);
    }
  }, [data]);

  const content = () => {
    if (loading) {
      return (
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTab}
              centered
              sx={{
                '& .MuiTab-root': {
                  color: '#ffffff',
                },
                '& .Mui-selected': {
                  color: 'primary.main',
                },
              }}
            >
              <Tab
                label={t('Components.Admin.Events.Participations')}
                value='1'
              />
              <Tab
                label={t('Components.Admin.Events.Cancellations')}
                value='2'
              />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <Participations
              participants={participants?.filter(
                (p) => p.status !== 'CANCELLED'
              )}
              eventId={props.event?.id}
              triggerRefetch={triggerRefetch}
            />
          </TabPanel>
          <TabPanel value='2'>
            <Participations
              participants={participants?.filter(
                (p) => p.status === 'CANCELLED'
              )}
              eventId={props.event?.id}
              triggerRefetch={triggerRefetch}
            />
          </TabPanel>
        </TabContext>
      </Box>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={`${props.event.name} - ${t(
        'Components.Admin.Events.ParticipationList'
      )}`}
      content={content()}
    />
  );
}
