import { ReactNode } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

type Props = {
  headers: string[]
}

export function RulesTableHead(props: Readonly<Props>): ReactNode {
  return (
    <TableHead>
      <TableRow>
        {
          props.headers.map((header) => {
            return (<TableCell key={header}>{header}</TableCell>);
          })
        }
      </TableRow>
    </TableHead>
  );
}