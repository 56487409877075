import { ReactNode, createElement } from 'react';
import { Typography, Grid } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

type Props = {
  icon: SvgIconComponent;
  text: string;
  noBorder?: boolean;
  to: string;
};

export function SectionLinkBox(props: Readonly<Props>): ReactNode {
  const nav = useNavigate();

  return (
    <Grid
      item
      className='section-item'
      xs={1.6}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',

        flexDirection: 'column',
        lineHeight: '1.375rem',
        letterSpacing: '0.0071em',
        textAlign: 'center',
        paddingRight: '20px',

        borderRight: props.noBorder
          ? '0'
          : '1px solid rgba(255, 255, 255, 0.2)',
      }}
      onClick={() => nav(props.to)}
    >
      {createElement(props.icon, {
        className: 'child',
        sx: {
          fontSize: '2.5rem',
          '@media(max-width:320px)': { fontSize: '2rem' },
        },
      })}
      <Typography
        className='child'
        component='p'
        sx={{
          padding: 0,
          marginTop: '5px',
          color: 'inherit',
          fontSize: '1rem',
          '@media(max-width: 425px)': {
            display: 'none',
          },
        }}
      >
        {props.text}
      </Typography>
    </Grid>
  );
}
