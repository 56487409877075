import { ReactNode } from 'react';
import { ListItemText, Checkbox, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { t } from 'i18next';
import { User } from 'types';

type Props = {
    label: string,
    id: string,
    value?: string[],
    i18nKey?: string,
    placeholder?: string,
    list?: string[],
    disabled?: boolean,
    multiple?: boolean
    onChange?: (SelectChangeEvent) => void,
    readonly?: boolean
    numbered?: boolean
    numberedValue?: number
    numberedList?: User[]
};

export function SelectField(props: Readonly<Props>): ReactNode {
    const convertToLocalString = (selected, i18nKey) => {
        if (!props.numbered) {
            if(typeof(selected) === 'string') {
                return props.i18nKey ? t(i18nKey + '.' + selected) : selected;
            }
            const i18nSelected = selected.map((s) => {
                if(!s) 
                    return '';
                return props.i18nKey ? t(i18nKey + '.' + s) : s;
            });
            return i18nSelected.join(', ');
        }

        return props.numberedList.find((u) => u.id === selected).profile?.nickname;
    };

    function sortedList() {
        if (props.list) {
            const listCopy = [...props.list];
            if (props.i18nKey) {
                listCopy.sort((a, b) => (t(props.i18nKey + '.' + a) > t(props.i18nKey + '.' + b) ? 1 : -1));
            } 
            listCopy?.sort((a, b) => a.localeCompare(b));
            return listCopy;
        }

        if (props.numberedList) {
            return props.numberedList;
        }
    }

    return (
        <FormControl
            variant='standard'
            sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                    borderBottom: '1px solid #fff',
                    height: 'inherit'
                },
                '& .MuiSelect-select': {
                    color: '#fff',
                    fontFamily: 'Neuton',
                },
                'label': {
                    fontSize: '1.1rem',
                    fontFamily: 'Neuton',
                    color: 'rgba(255, 255, 255, 0.7)'
                }
            }}
        >
            <InputLabel id={props.id + '-selection-label'}>{props.label}</InputLabel>
            <Select
                inputProps={{
                  readOnly: props.readonly
                }}
                labelId={props.id + '-selection-label'}
                id={props.id + '-selection'}
                label={props.id}
                value={props.value || props.numberedValue || []}
                renderValue={(selected) => convertToLocalString(selected, props.i18nKey)}
                fullWidth
                multiple={props.multiple}
                onChange={props.onChange}
                placeholder={props.placeholder}
                disabled={props.disabled}
                sx={{
                    height: '60px',
                    '& .MuiSvgIcon-root': {
                        color: 'primary.main'
                    },
                }}
                MenuProps={{
                    slotProps: {
                        paper: {
                            style: {
                                backgroundColor: '#1f1f1f'
                            }
                        }
                    }
                }}
            >
                {sortedList()?.map((name) => (
                    <MenuItem
                        key={props.numbered ? name?.id : name}
                        value={props.numbered ? name?.id : name}
                        sx={{
                            backgroundColor: '#1e1e1e',
                            color: '#fff',
                            fontFamily: 'Neuton',
                            '&:hover': {
                                backgroundColor: '#303030',
                            },
                        }}
                    >
                        {props.numbered ? 
                            <ListItemText primary={name?.profile?.nickname} />
                        :
                        <>
                            <Checkbox sx={{
                                '& .MuiSvgIcon-root': {
                                    color: 'primary.main'
                                }
                            }} checked={props.value?.indexOf(name) > -1} />
                            <ListItemText primary={props.i18nKey ? t(props.i18nKey + '.' + name) : name} />
                        </>
                        }
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
