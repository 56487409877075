import { CHARACTERS_URL, DRAPERIES_URL, EMPTY } from 'const';
import { ThestralDataGrid } from '../ThestralDataGrid';
import { useFetch } from 'func';
import { Character } from 'types';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

type Props = {
  onRowClick?: (elem) => void;
  isDrapery?: boolean;
  isArchivedDrapery?: boolean;
};

export function ApprovalGrid(props: Readonly<Props>) {
  const URL = getUrl();
  const { data, loading: charLoading } = useFetch<Character[]>(URL);
  const [characters, setCharacters] = useState<Character[]>();
  const [loading, setLoading] = useState<boolean>(true);

  function getUrl() {
    if (!props?.isDrapery) {
      return `${CHARACTERS_URL}/pending`;
    }
    return `${DRAPERIES_URL}/${
      props.isArchivedDrapery ? 'archived' : 'pending'
    }`;
  }

  useEffect(() => {
    if (data) {
      setCharacters(data);
      setLoading(false);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ThestralDataGrid
          rows={characters || EMPTY}
          sx={{
            display: characters?.length == 0 ? 'none' : 'block',
            minWidth: '300px',
            width: '100%',
          }}
          onRowClick={props.onRowClick}
          columns={[
            {
              field: 'name',
              headerName: t('Generic.Name'),
              sortable: true,
              flex: 1,
            },
            {
              field: 'playerName',
              headerName: t('Components.PlayerCharacters.PlayerName'),
              sortable: true,
              flex: 1,
              align: 'right',
              headerAlign: 'right',
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          hideFooter
          pageSizeOptions={[5, 10]}
          loading={charLoading}
        />
      )}
    </>
  );
}
