import { Grid } from '@mui/material';

import { TextField } from 'components/ui';
import { t } from 'i18next';

type Props = {
  token: string
  email: string
}

export function RegisterTokenEmail(props: Readonly<Props>) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField 
          label={t('Components.Register.Token')}
          value={props.token}
          readonly
          variant='filled'
          sx={{
            backgroundColor: '#0b0b0b',
            borderRadius: '5px'
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField 
          label={t('Components.Register.Email')}
          value={props.email}
          readonly
          variant='filled'
          sx={{
            backgroundColor: '#0b0b0b',
            borderRadius: '5px'
          }}
        />
      </Grid>
    </Grid>
  );
}
