import { FormControlLabel as MuiFormControlLabel, Radio } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  value: string,
  label: string
}

export function FormControlLabel(props: Readonly<Props>): ReactNode {
  return (  
    <MuiFormControlLabel 
      sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Neuton', color: 'rgba(255, 255, 255, 0.7)' } }} 
      value={props.value}
      control={<Radio sx={{ color: '#ffffff' }}  />} 
      label={props.label} 
    />
  );
}
