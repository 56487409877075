import { useContext, ReactNode } from 'react';
import { Box, Grid} from '@mui/material';
import { t } from 'i18next';

import { CharacterContext } from 'contexts';
import { DateField } from 'components/ui';

type Props = {
  readonly: boolean
}

export function ActiveSince(props: Readonly<Props>) : ReactNode {
  const {char, setChar} = useContext(CharacterContext);

  return (
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <DateField 
          label={t('Components.NonPlayerCharacters.AtHogwartsSince')}
          value={char?.activeSince}
          onChange={(newValue) =>  newValue && setChar({...char, activeSince: newValue.toISODate()})}
          readonly={props.readonly}
          maxDate={'1999-12-31'}
          minDate={'0800-01-01'}
        />
      </Box>
    </Grid>
  );
}