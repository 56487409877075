import { useEffect, forwardRef, useState } from 'react';
import {
  Box,
  CardMedia,
  Slide,
  SlideProps,
  CircularProgress,
} from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Button, Dialog } from 'components/ui';
import {
  ApprovalStatus,
  ContactPerson,
  DraperyApprovalStatus,
  DraperyDetail,
  ThestralDataGrid,
} from 'components/thestral';

import { Character, DraperyCheck } from 'types';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import {
  HTTP_METHOD,
  STATIC_URL,
  EMPTY,
  PEOPLE_URL,
  DRAPERIES_URL,
} from 'const';
import { GridRenderCellParams } from '@mui/x-data-grid';

type Props = {
  person: Character;
  open: boolean;
  onClose: () => void;
  filter?: string[];
};

const Transition = forwardRef((props: SlideProps, ref: React.Ref<unknown>) => (
  <Slide direction='up' ref={ref} {...props} />
));
Transition.displayName = 'Transition';

export function DraperyCheckList(props: Readonly<Props>) {
  const { data, triggerRefetch } = useFetch<DraperyCheck[]>(
    `${PEOPLE_URL}/${props.person?.id}/drapery`
  );
  const fetchFromBackend = useFetchFromBackend();

  const [draperyChecks, setDraperyChecks] = useState<DraperyCheck[]>();
  const [contact, setContact] = useState('');
  const [loading, setLoading] = useState<boolean>(true);

  const [currentDrapery, setCurrentDrapery] = useState<DraperyCheck>(undefined);
  const [detailOpen, setDetailOpen] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (data) {
      const filtered = data?.filter((d) => props.filter?.includes(d.status));
      setDraperyChecks(filtered);
      setLoading(false);
    }
  }, [data]);

  function onDraperyRowClick(elem) {
    setCurrentDrapery(elem.row);
    setDetailOpen(true);
  }

  function renderApprovals(elem) {
    return (
      <>
        {Object.entries(elem?.approval?.approvals).map((approval) => {
          return (
            <ApprovalStatus
              key={approval[0]}
              state={approval[1] as boolean}
              minWidth='0px'
            />
          );
        })}
      </>
    );
  }

  const onApprove = (state: boolean) => {
    const update = state ? 'approve' : 'decline';
    const body = JSON.stringify({ responsible: contact });
    fetchFromBackend(`${DRAPERIES_URL}/${currentDrapery.id}/${update}`, {
      method: HTTP_METHOD.PUT,
      body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        if (response.ok) {
          triggerRefetch();
        }
        showSnackbar(`${t('Generic.ApprovalChange')} ${result}`, severity);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
        setDetailOpen(false);
        setCurrentDrapery(undefined);
        setContact('');
      });
  };

  function isFourEyes(type: boolean) {
    if (currentDrapery?.approval) {
      const count = Object.values(currentDrapery?.approval?.approvals).filter(
        (value) => value === type
      ).length;
      return count >= 1;
    }

    return true; // Old drapery check item without an approval object present
  }

  const content = () => {
    if (!draperyChecks) return <></>;

    return (
      <ThestralDataGrid
        rows={draperyChecks || EMPTY}
        onRowClick={onDraperyRowClick}
        rowHeight={200}
        columns={[
          {
            field: 'thumbnail',
            headerName: t('Generic.Preview'),
            align: 'right',
            headerAlign: 'left',
            flex: 2,
            renderCell: (params: GridRenderCellParams) =>
              !loading ? (
                <CardMedia
                  sx={{ objectFit: 'contain' }}
                  component='img'
                  height='100%'
                  image={`${STATIC_URL}/uploads/${params.row.imagePath}`}
                />
              ) : (
                <></>
              ),
          },
          {
            field: 'note',
            headerName: t('Generic.Note'),
            sortable: true,
            flex: 2,
          },
          {
            field: 'approvers',
            headerName: t('Generic.Approvals'),
            align: 'right',
            headerAlign: 'right',
            flex: 1,
            renderCell: (params: GridRenderCellParams) =>
              !loading && params.row.approval != null ? (
                renderApprovals(params.row)
              ) : (
                <></>
              ),
          },
          {
            field: 'status',
            headerName: t('Generic.Status'),
            headerAlign: 'right',
            sortable: true,
            align: 'right',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <DraperyApprovalStatus drapery={params.row} />
            ),
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        hideFooter
        hideFooterPagination
        pageSizeOptions={[5, 10]}
        loading={loading}
      />
    );
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        content={content()}
        title={`${t('Components.Drapery.Approvals.TitleShort')} - ${
          props.person?.name
        }`}
        fullScreen
        noActions
      />

      {currentDrapery && (
        <Dialog
          open={detailOpen}
          onClose={() => setDetailOpen(false)}
          title={`${t('Components.Drapery.Approvals.TitleShort')} - ${
            props.person?.name
          }`}
          content={
            loading ? (
              <Box
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <DraperyDetail
                  drapery={currentDrapery}
                  triggerRefetch={triggerRefetch}
                />
                {currentDrapery?.status == 'PENDING' ? (
                  <ContactPerson
                    contact={contact}
                    callback={(event) => setContact(event.target.value)}
                  />
                ) : (
                  <></>
                )}
              </>
            )
          }
          actions={
            loading ? (
              <></>
            ) : (
              <>
                <Button
                  disabled={!isFourEyes(true)}
                  onClick={() => onApprove(true)}
                  color='success'
                  text={
                    <Trans i18nKey='Components.Drapery.Approvals.Approve' />
                  }
                />
                <Button
                  disabled={!isFourEyes(false)}
                  onClick={() => onApprove(false)}
                  color='error'
                  sx={{ marginLeft: '5px' }}
                  text={
                    <Trans i18nKey='Components.Drapery.Approvals.Decline' />
                  }
                />
              </>
            )
          }
        />
      )}
    </>
  );
}
