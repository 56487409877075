import { ReactNode } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { Button } from 'components/ui';

type Props = {
  title: string;
  text: string;
  dates?: string;
  itDates?: string;
  category?: string;
  to?: string;
  con?: boolean;
};
export function RulesContent(props: Readonly<Props>): ReactNode {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: '100%',
        margin: 0,
      }}
    >
      {props.category && (
        <Grid xs={12}>
          <Card
            sx={{
              borderRadius: '0px',
              padding: '1px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              color: '#000',
              fontSize: '0.95rem',
              backgroundColor: '#dedede',
              width: '40px',
              fontFamily: 'Bebas',
              marginBottom: '10px',
            }}
          >
            {props.category}
          </Card>
        </Grid>
      )}
      <Grid xs={12}>
        <Typography
          variant='h1'
          component='p'
          sx={{
            wordBreak: 'break-word',
            fontWeight: '500',
            lineHeight: '2rem',
            color: '#fff',
            fontFamily: 'Bebas',
            fontSize: '2.4em',

            '@media (max-width:600px)': {
              fontSize: '1.8rem',
            },
          }}
        >
          {props.title}
        </Typography>
      </Grid>
      {props.dates && (
        <Grid lg={12} xl={6}>
          <Typography
            variant='h6'
            component='p'
            color='secondary'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <Card
              sx={{
                borderRadius: '0px',
                padding: '1px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                color: '#000',
                fontSize: '0.95rem',
                backgroundColor: '#dedede',
                width: '40px',
                fontFamily: 'Bebas',
                marginRight: '5px',
              }}
            >
              OT
            </Card>
            {props.dates}
          </Typography>
        </Grid>
      )}
      {props.itDates && (
        <Grid lg={12} xl={6}>
          <Typography
            variant='h6'
            component='p'
            color='primary'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <Card
              sx={{
                borderRadius: '0px',
                padding: '1px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                color: '#000',
                fontSize: '0.95rem',
                backgroundColor: '#dedede',
                width: '40px',
                fontFamily: 'Bebas',
                marginRight: '5px',
              }}
            >
              IT
            </Card>
            {props.itDates}
          </Typography>
        </Grid>
      )}
      <Grid xs={12}>
        <Typography
          variant='body1'
          component='p'
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            fontSize: '14px',
            fontFamily: 'Neuton',
            letterSpacing: '0.0071428571em',
            whiteSpace: 'pre-line',
          }}
        >
          {props.text}
        </Typography>
      </Grid>
      {props.to && (
        <Grid xs={12}>
          <Button
            color='primary'
            href={props.to}
            sx={{
              color: '#ffffff',
              letterSpacing: '0.0892857143em',
            }}
            text={
              <Trans
                i18nKey={
                  props.con
                    ? 'Components.Rules.WorldBuilding.Dailyprophet'
                    : 'Components.Rules.ReadOn'
                }
              />
            }
          />
        </Grid>
      )}
    </Grid>
  );
}
