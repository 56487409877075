import { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { HTTP_METHOD } from 'const';
import { FailureData, FetchParams, InitParam, InputParam, UseFetchReturn } from 'types';
import { SnackbarContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import ICAL from 'ical.js';
import { DateTime } from 'luxon';

export  function getSocialMedia(users, character) {
    const user = users?.find((u) => {
      return u.profile?.characters.find((char) => char.id === character.id);
    });

    return user?.profile.socialMediaAccounts;
  }

export function getPlayerName(users, character) {
  const user = users?.find((u) => {
    return u.profile?.characters.find((char) => char.id === character.id);
  });
  return user?.profile.nickname ?? '';
}

export function fetchFromBackend<SuccessData>(params: FetchParams<SuccessData>) {
  const request = {method: params.method, headers: params.headers};
  fetch(params.url, request)
    .then((response) =>
      response.json().then((json) =>
        (response.status < 400 ? params.onSuccess(json as SuccessData) : params.onFailure(json as FailureData)))
      )
    .catch((error) => params.onError(error));
}

export const useFetchFromBackend = (publicLoc=false) => {
  const auth = useAuth();

  return (input:  InputParam, init: InitParam) => {
    const token = auth.user?.access_token;

    const contentType = (init.body instanceof FormData) ? {} : {'Content-Type': 'application/json'};
    const authHeader = !publicLoc ? {'Authorization': `Bearer ${token}`} : {};
    return fetch(input, {
      ...init,
      headers: {
        ...contentType,
        ...authHeader,
        ...init?.headers
      }
    });
 };
};

export const useFetch = <SuccessData = unknown>(
    url?: string,
    method: HTTP_METHOD = HTTP_METHOD.GET,
    body?: BodyInit,
    publicFetch?: boolean
): UseFetchReturn<SuccessData> => {
    const [data, setData] = useState<SuccessData>();
    const [loading, setLoading] = useState(!!url);
    const [failureData, setFailureData] = useState<FailureData>();
    const [error, setError] = useState<string>();
    const [rerenderValue, setRerenderValue] = useState(Symbol());
    const triggerRefetch = () => setRerenderValue(Symbol());

    const auth = useAuth();

    const token = auth.user?.access_token;
    const publicLoc = url?.includes('/public/') || publicFetch;
    const headers = publicLoc ? {} : { 'Authorization': `Bearer ${token}` };

    useEffect(() => {
      if ((!url || !auth.isAuthenticated || auth.isLoading) && !publicLoc) {
        return;
      }

      setLoading(true);
      setError(undefined);
      setData(undefined);
      setFailureData(undefined);
      fetchFromBackend<SuccessData>({
          url,
          onSuccess: (json) => {
              setData(json);
              setLoading(false);
          },
          onError: (err) => {
              setError(err.message);
              setLoading(false);
          },
          onFailure: (json) => {
              setFailureData(json);
              setLoading(false);
          },
          method,
          headers,
          body
      });
    }, [url, method, body, rerenderValue, auth.isAuthenticated, auth.isLoading]);

    return {
        data,
        failureData,
        loading,
        error,
        triggerRefetch,
    };
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if(context === undefined) {
    throw new Error('Snackbar must be consumed within Provider');
  }
  return context;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function toIcalString(event) {
  const { id, title, start, end } = event;
  const vevent = new ICAL.Component('vevent');
  vevent.addPropertyWithValue('uid', id);
  vevent.addPropertyWithValue('summary', title);
  vevent.addPropertyWithValue('dtstart', ICAL.Time.fromJSDate(new Date(start)));
  vevent.addPropertyWithValue('dtend', ICAL.Time.fromJSDate(new Date(end)));

  const vcal = new ICAL.Component('vcalendar');
  vcal.addSubcomponent(vevent);

  return vcal.toString();
}

export function parseDate(date: string) {
  return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
}
