import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem as MuiMenuItem, ListItemIcon, ListItemText } from '@mui/material';

type Props = {
  icon: ReactNode,
  text: string,
  to?: string
  onClick?: () => void
}
export function MenuItem(props: Readonly<Props>): ReactNode {
  return (
    <MuiMenuItem 
      to={props.to}
      sx={{ paddingTop: '3px', paddingBottom: '3px' }} 
      component={Link}
      onClick={props.onClick} 
    >
      <ListItemIcon sx={{ color: 'white.main' }}>
        {props.icon}
      </ListItemIcon>
      <ListItemText primary={props.text} primaryTypographyProps={{ fontSize: '1.25rem' }}/>
    </MuiMenuItem>
  );
}