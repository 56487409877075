import { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';

import { GridHeadline } from 'components/thestral';

import { ProfileData } from './ProfileData';
import { SocialMediaData } from './SocialMediaData';

import { LegalData } from './LegalData';
import { OtherData } from './OtherData';
import { FormikProps, FormikValues } from 'formik';

type Props = {
  formik: FormikProps<FormikValues>;
  width?: number;
  public?: boolean;
};

export function PersonalData(props: Readonly<Props>): ReactNode {
  return (
    <Grid sm={12} md={6}>
      <GridHeadline title={t('Components.Profile.PersonalData.Title')} />

      <ProfileData formik={props.formik} />
      <SocialMediaData formik={props.formik} />
      <LegalData formik={props.formik} />
      {!props.public && <OtherData formik={props.formik} />}
    </Grid>
  );
}
