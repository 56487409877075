import { createClient } from 'webdav';
import ICAL from 'ical.js';

import { toIcalString } from 'func';

const client = createClient(
  process.env.REACT_APP_CALDAV_URL,
  {
    username: process.env.REACT_APP_CALDAV_USER,
    password: process.env.REACT_APP_CALDAV_PASS
  }
);

const parseEvent = (icsData) => {
  const jcalData = ICAL.parse(icsData);
  const comp = new ICAL.Component(jcalData);
  const vevents = comp.getAllSubcomponents('vevent');

  return vevents.map(vevent => {
    const id = vevent.getFirstPropertyValue('uid');
    const start = vevent.getFirstPropertyValue('dtstart').toJSDate();
    const end = vevent.getFirstPropertyValue('dtend').toJSDate();
    const title = vevent.getFirstPropertyValue('summary');
    
    return {
      id,
      title,
      start,
      end
    };
  });
};

export const getCalendarEvents = async () => {
  const files = await client.getDirectoryContents('/');

  let contents = [];
  if (Array.isArray(files)) {
    contents = files;
  } else {
    contents = files.data;
  }

  const icsFiles = contents.filter(file => file.mime === 'text/calendar');

  const promises = icsFiles.map(file => client.getFileContents(file.filename, {format: 'text'}));
  const icsDataArray = await Promise.all(promises);
  
  return icsDataArray.flatMap(icsData => parseEvent(icsData));
};

export const createEvent = async (event) => {
  const icalString = toIcalString(event);
  await client.putFileContents(`/${event.id}.ics`, icalString);
};

export const updateEvent = async (event) => {
  const icalString = toIcalString(event);
  await client.putFileContents(`/${event.id}.ics`, icalString);
};

export const deleteEvent = async (event) => {
  await client.deleteFile(`/${event.id}.ics`);
};