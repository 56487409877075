import { useState, ReactNode, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { PersonOff } from '@mui/icons-material';
import { t } from 'i18next';

import { Headline, InactiveDialog, ProfileDialog } from 'components/thestral';
import { Button, Dialog } from 'components/ui';

import { HouseList } from 'pages/PlayerCharacters/HouseList';
import { NpcList } from 'pages/NonPlayerCharacters/NpcList';

import { CharacterContext } from 'contexts';
import { Character, HouseType, NpcType } from 'types';

type Props = {
  title: string;
  npcs?: boolean;
  dialogContent: ReactNode;
};

export function Characters(props: Readonly<Props>): ReactNode {
  const [char, setChar] = useState<Character>(undefined);
  const charContextValue = useMemo(() => ({ char, setChar }), [char, setChar]);

  const [open, setOpen] = useState<boolean>(false);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [inactiveOpen, setInactiveOpen] = useState<boolean>(false);

  const title = props.title;
  const breadcrumbs = [{ href: '/', title: t('Generic.Start') }];

  const onRowClick = (elem) => {
    setChar(elem);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    setChar({ ...char, readonly: false });
  };

  return (
    <Box
      className='content'
      sx={{
        margin: '30px 50px 30px 0',
        '@media (max-width:600px)': {
          margin: '0',
        },
      }}
    >
      <Headline title={title} breadcrumbs={breadcrumbs} />

      <Grid container spacing={2}>
        {!props.npcs && (
          <Grid item xs={12}>
            <Button
              text={t('Generic.Inactive')}
              color='secondary'
              startIcon={<PersonOff />}
              onClick={() => setInactiveOpen(true)}
            />
          </Grid>
        )}
        {props.npcs
          ? [
              'TEACHER',
              'STAFF',
              'FOUNDER',
              'GHOST',
              'EXTERNAL',
              'PORTRAIT',
            ].map((type: NpcType) => (
              <Grid item xs={12} md={6} key={type}>
                <NpcList
                  type={type}
                  onRowClick={onRowClick}
                  sx={{
                    marginLeft: '20px',
                    maxWidth: '700px',
                    minWidth: '10px',
                  }}
                />
              </Grid>
            ))
          : ['GRYFFINDOR', 'RAVENCLAW', 'HUFFLEPUFF', 'SLYTHERIN'].map(
              (house: HouseType) => (
                <Grid item xs={12} md={6} key={house}>
                  <HouseList
                    house={house}
                    onRowClick={onRowClick}
                    sx={{
                      paddingLeft: '20px',
                      maxWidth: '700px',
                      minWidth: '10px',
                    }}
                  />
                </Grid>
              )
            )}
      </Grid>

      <InactiveDialog
        open={inactiveOpen}
        onClose={() => setInactiveOpen(false)}
        onRowClick={onRowClick}
      />
      <CharacterContext.Provider value={charContextValue}>
        {open && char && (
          <Dialog
            sx={{
              '& .MuiPaper-root': {
                bgcolor: '#1e1e1e',
                marginBottom: '0',
              },
            }}
            open={open}
            onClose={() => closeDialog()}
            title={char.name}
            content={props.dialogContent}
            titleButton={
              char?.profileVisibility === 'FORALL' && (
                <Button
                  text='Profil'
                  color='secondary'
                  onClick={() => setProfileOpen(true)}
                />
              )
            }
          />
        )}
      </CharacterContext.Provider>

      {profileOpen && char && (
        <ProfileDialog
          open={profileOpen}
          onClose={() => setProfileOpen(false)}
          id={char?.userId}
          noDataProtection
          public
        />
      )}
    </Box>
  );
}
