import { ReactNode } from 'react';
import { t } from 'i18next';

import { RulesTab, RulesTabContext } from 'components/thestral';

import { RuleComponent } from 'types';

export function IT(): ReactNode {
  const title = t('Components.Rules.InTimeRules.Title');
  const breadcrumbs = [
    {href: '/', title: t('Generic.Start')},
    {href: '/rules', title: t('Components.Rules.Title')}
  ];

  const items = [
    {name: t('Components.Rules.InTimeRules.Gamesystem'), component: <RulesTab slug='gamesystem' ruleType='InTimeRules' />},
    {name: t('Components.Rules.InTimeRules.Rules'), component: <RulesTab slug='rules' ruleType='InTimeRules' />},
    {name: t('Components.Rules.InTimeRules.Ambience'), component: <RulesTab slug='ambience' ruleType='InTimeRules' />},
    {name: t('Components.Rules.InTimeRules.Charactercreation'), component: <RulesTab slug='charactercreation' ruleType='InTimeRules' />},
    {name: t('Components.Rules.InTimeRules.Spelllist'), component: <RulesTab slug='spelllist' ruleType='InTimeRules' />},
    {name: t('Components.Rules.InTimeRules.Potions'), component: <RulesTab slug='potions' ruleType='InTimeRules' />},
    {name: t('Components.Rules.InTimeRules.Leisure'), component: <RulesTab slug='leisure' ruleType='InTimeRules' />},
  ];

  return (
    <RulesTabContext
      title={title}
      items={items as RuleComponent[]}
      breadcrumbs={breadcrumbs}
    />
  );
}