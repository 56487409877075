import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { CloudUpload } from '@mui/icons-material';
import { Document, Page } from 'react-pdf';

import { Button, Dialog, TextField, VisuallyHiddenInput } from 'components/ui';

import { useFetchFromBackend, useSnackbar } from 'func';
import { EVENTS_URL, HTTP_METHOD, STATIC_URL, TEXT_FIELD_BIG } from 'const';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Event } from 'types';

type Props = {
  open: boolean;
  event: Event;
  onClose: () => void;
  triggerRefetch: () => void;
};

export function ProphetDialog(props: Readonly<Props>) {
  const [event, setEvent] = useState<Event>();
  const [loading, setLoading] = useState<boolean>(true);
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();
  const [prophet, setProphet] = useState<File>();
  const [file, setFile] = useState<File>();

  const [pdfBuffer, setPdfBuffer] = useState(null);
  const memoFile = useMemo(() => {
    return pdfBuffer ? { url: pdfBuffer } : null;
  }, [pdfBuffer]);

  const onClose = () => {
    setEvent(null);
    props.onClose();
  };

  useEffect(() => {
    setEvent(props.event);
    setLoading(false);
  }, [props.event]);

  const onFileChange = (file) => {
    setFile(file);
  };

  useEffect(() => {
    const readFile = async () => {
      if (prophet) {
        try {
          const buffer = await prophet.arrayBuffer();
          const blob = new Blob([buffer], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          setPdfBuffer(url);
        } catch (error) {
          console.error('Error obtaining ArrayBuffer: ', error);
        }
      } else {
        setPdfBuffer(undefined);
      }
    };

    readFile();
  }, [prophet]);

  const onEdit = () => {
    if (event?.summary != props.event?.summary) {
      updateSummary();
    }

    if (prophet) {
      saveProphet();
    }

    if (file) {
      saveImage();
    }
  };

  const saveProphet = () => {
    setLoading(true);
    const body = new FormData();
    body.append('uploadFile', prophet);
    fetchFromBackend(`${EVENTS_URL}/${event?.id}/daily-prophet`, {
      method: HTTP_METHOD.POST,
      body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        showSnackbar(
          `${t('Components.Admin.Events.ProphetUpload')} ${result}`,
          severity
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveImage = () => {
    setLoading(true);
    const body = new FormData();
    body.append('uploadFile', file);
    fetchFromBackend(`${EVENTS_URL}/${event?.id}/image`, {
      method: HTTP_METHOD.POST,
      body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        showSnackbar(
          `${t('Components.Admin.Events.ImageUpload')} ${result}`,
          severity
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateSummary = () => {
    setLoading(true);
    const body = JSON.stringify(event);
    fetchFromBackend(`${EVENTS_URL}/${event?.id}`, {
      method: HTTP_METHOD.PATCH,
      body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          props.triggerRefetch();
        }

        showSnackbar(
          `${t('Components.Admin.Events.ProphetUpdate')} ${result}`,
          severity
        );
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const setSummary = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const summary = evt.target.value;
    setEvent({ ...event, summary });
  };

  const content = () => {
    return (
      <Grid container columnSpacing={2} sx={{ justifyContent: 'flex-start' }}>
        <Grid item xs={12}>
          <TextField
            id='event.summary'
            multiline
            label={t('Components.Admin.Events.Summary')}
            rows={10}
            defaultValue={event?.summary}
            onChange={setSummary}
            helperText={`${event?.summary?.length ?? '0'}/${TEXT_FIELD_BIG}`}
            error={event?.summary?.length > TEXT_FIELD_BIG}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='selection-label-prophet' sx={{}}>
            {t('Components.Admin.Events.ProphetFile')}
          </InputLabel>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='selection-label-con-image'>
            {t('Components.Admin.Events.ImageFile')}
          </InputLabel>
        </Grid>

        <Grid item xs={6} sx={{}}>
          <Button
            component='label'
            color='primary'
            role={undefined}
            tabIndex={-1}
            startIcon={<CloudUpload />}
            sx={{
              width: 'auto',
              marginBottom: '10px',
            }}
            text={
              <>
                {t('Generic.Upload')}
                <VisuallyHiddenInput
                  type='file'
                  onChange={(event) => setProphet(event.target.files[0])}
                  accept='application/pdf'
                />
              </>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Button
            component='label'
            role={undefined}
            tabIndex={-1}
            fullWidth
            color='success'
            startIcon={<CloudUpload />}
            sx={{
              width: 'auto',
              marginBottom: '10px',
            }}
            text={
              <>
                <Typography>{t('Generic.Upload')}</Typography>
                <VisuallyHiddenInput
                  type='file'
                  onChange={(event) => onFileChange(event.target.files[0])}
                  accept='image/*'
                />
              </>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Box
            sx={{
              '& .react-pdf__Page': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            {event?.dailyProphet &&
              event?.dailyProphet == props.event?.dailyProphet && (
                <>
                  <Document
                    file={`${STATIC_URL}/uploads/${event?.dailyProphet}`}
                  >
                    <Page
                      renderAnnotationLayer={false}
                      pageNumber={1}
                      height={200}
                      renderTextLayer={false}
                    />
                  </Document>
                  <Typography variant='body1'>
                    {t('Generic.File')}: {event?.dailyProphet.split('/')[2]}
                  </Typography>
                </>
              )}
            {memoFile && (
              <>
                <Document file={memoFile}>
                  <Page
                    renderAnnotationLayer={false}
                    pageNumber={1}
                    height={200}
                    renderTextLayer={false}
                  />
                </Document>
                <Typography variant='body1'>
                  {t('Generic.File')}: {prophet?.name}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            '& .MuiButtonBase-root': {
              fontFamily: 'Bebas',
            },
          }}
        >
          {file && (
            <Typography variant='body1'>
              {t('Generic.File')}: {file?.name}
            </Typography>
          )}
          {event?.imagePath && (
            <img
              src={`${STATIC_URL}/uploads/${event.imagePath}`}
              alt='Con Image'
              style={{ width: '250px' }}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      title={event?.name}
      content={loading ? <CircularProgress /> : content()}
      width='750px'
      actions={
        !loading && (
          <Button
            color='success'
            onClick={onEdit}
            disabled={
              (event?.summary?.length > TEXT_FIELD_BIG ||
                event?.summary == props.event?.summary) &&
              !prophet &&
              !file
            }
            text={<Trans i18nKey='Generic.Save' />}
          />
        )
      }
    />
  );
}
