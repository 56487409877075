import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Box, CircularProgress, Grid } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { ApprovalDataGrid, GridHeadline, ThestralCheckbox } from 'components/thestral';
import { Button, DateField, Dialog, TextField } from 'components/ui';

import { Approvals, Signup, User } from 'types';
import { HTTP_METHOD, ROLES_URL, SIGNUP_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';

type Props = {
  open: boolean
  onClose: () => void
  signup: Signup
  triggerRefetch: () => void
}

export function SignupApprovalDialog(props: Readonly<Props>) {
  const  auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const fetchFromBackend = useFetchFromBackend();
  const [loading, setLoading] = useState<boolean>(false);
  const { data: approvers, loading: approversLoading } = useFetch<User[]>(`${ROLES_URL}/PALADIN`);
  const { data: approvalData, loading: approvalLoading, triggerRefetch } = useFetch<Approvals>(`${SIGNUP_URL}/${props.signup.id}/approval`);

  const onRowClick = (elem) => {
    if (auth.user?.profile.email != elem.row.email) {
      return;
    }
    const state = approvalData?.approvals[elem.row.id];
    approve(!state);
  };

  const authorise = (state: boolean) => {
    setLoading(true);
    const update = state ? 'approve' : 'disapprove';
    fetchFromBackend(`${SIGNUP_URL}/${props.signup.id}/${update}`, {method: HTTP_METHOD.PATCH})
      .then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;

          showSnackbar(`${t('Generic.ApprovalChange')} ${result}`, severity);
          if (response.ok) {
            props.triggerRefetch();
            props.onClose();
          }
        }
      ) 
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

  };

  const approve = (newState: boolean) => {
    fetchFromBackend(`${SIGNUP_URL}/${props.signup.id}/approval?approval=${newState}`, {method: HTTP_METHOD.PATCH})
      .then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          if (response.ok) {
            triggerRefetch();
          }

          showSnackbar(`${t('Components.Characters.Approvals.TitleShort')} ${result}`, severity);
        }
      ) 
      .catch((error) => console.error(error));
  };

  return (
    <Dialog 
      width='1200px'
      open={props.open}
      onClose={props.onClose}
      title={`${t('Components.Signup.Approvals.TitleShort')} - ${props.signup?.nickname}`}
      content={
        loading ? 
          <Box sx={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
            <CircularProgress /> 
          </Box>
        :
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GridHeadline 
              title='Freigaben' 
            />
          </Grid>
          <Grid item xs={12}>
          { approversLoading
            ? 
              <Box sx={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                <CircularProgress /> 
              </Box>
            : 
              <ApprovalDataGrid 
                approvers={approvers}
                approvalData={approvalData}
                loading={approvalLoading} 
                onRowClick={onRowClick}
              />
          }
          </Grid>
          <Grid item xs={12} sx={{marginTop: '20px'}}>
            <GridHeadline 
              title={t('Signup.Approvals.Singular')} 
            />
          </Grid>
          <Grid item xs={1}>
            <TextField 
              id='pronouns'
              label={t('Generic.Pronouns')} 
              readonly
              defaultValue={props.signup?.pronouns}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id='firstname'
              label={t('Components.Profile.PersonalData.LegalData.FirstName')} 
              readonly
              defaultValue={props.signup?.firstname}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id='lastname'
              label={t('Components.Profile.PersonalData.LegalData.LastName')} 
              defaultValue={props.signup?.lastname}
              readonly
            />
          </Grid>
          <Grid item xs={3}>
            <TextField 
              id='nickname'
              label={t('Generic.Nickname')} 
              defaultValue={props.signup?.nickname}
              readonly
            />
          </Grid>
          <Grid item xs={2} sx={{alignContent: 'flex-end'}}>
            <DateField 
              label={t('Generic.DateOfBirth')} 
              value={props.signup?.dateOfBirth}
              readonly
            />
          </Grid>
          <Grid item xs={5}>
            <TextField 
              id='email'
              label={t('Generic.Email')} 
              defaultValue={props.signup?.email}
              readonly
            />
          </Grid>
          <Grid item xs={5}>
            <TextField 
              id='discord'
              label={t('Generic.Discord')} 
              defaultValue={props.signup?.discord}
              readonly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              id='description'
              label={t('Components.Signup.Description')}
              multiline
              rows={5}
              value={props.signup?.description}
              readonly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              id='houses'
              label={t('Components.Signup.Houses')}
              value={props.signup?.houses}
              multiline
              rows={5}
              readonly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              id='why'
              label={t('Components.Signup.Why')}
              value={props.signup?.why}
              multiline
              rows={5}
              readonly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              id='experience'
              label={t('Components.Signup.Experience')}
              value={props.signup?.experience}
              multiline
              rows={5}
              readonly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              id='other'
              label={t('Components.Signup.Other')}
              value={props.signup?.other}
              multiline
              rows={5}
              readonly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              id='affiliate'
              label={t('Components.Signup.Affiliate')}
              value={props.signup?.affiliate}
              readonly
            />
          </Grid>
          <Grid item xs={4}>
            <ThestralCheckbox 
              id='nextCon'
              text={t('Components.Signup.NextCon')} 
              checked={props.signup?.nextCon}
            />
          </Grid>
        </Grid>
      }
      actions={
        <>
          <Button onClick={() => authorise(true)} color='success' text={<Trans i18nKey='Components.Signup.Approvals.Approve' />} />
          <Button onClick={() => authorise(false)} color='error' text={<Trans i18nKey='Components.Signup.Approvals.Decline' />} />
        </>
      }
    />
  );
}
