import { Checkbox, FormControlLabel, FormGroup, SxProps } from '@mui/material';

type Props = {
    id: string,
    checked: boolean
    onChange?: (evt) => void
    text?: string
    sx?: SxProps
}

export function ThestralCheckbox(props: Readonly<Props>) {
    return (
        <FormGroup >
            <FormControlLabel
                sx={{
                    ...props.sx,
                    color: '#fff',
                    fontFamily: 'Neuton',
                    '&:hover': {
                        backgroundColor: '#303030',
                    },
                }}
                onChange={props.onChange}
                control={
                    <Checkbox 
                        id={props.id}
                        checked={props.checked}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                color: 'primary.main'
                            }
                        }} 
                    />
                }
                label={props.text}
            />
        </FormGroup>
    );
}
