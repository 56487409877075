import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';

type Props = {
  nickname: string;
};

export function Greeting(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='h1'
      sx={{
        fontSize: '4rem',
        fontWeight: '700',
        lineHeight: '60px',
        margin: '30px 0 50px 30px',
        '@media (max-width:600px)': {
          fontSize: '3rem',
          lineHeight: '50px',
          margin: '0px 0 30px 0px',
        },
        '@media (max-width:400px)': {
          fontSize: '2rem',
          lineHeight: '40px',
          margin: '0px 0 20px 0',
        },
      }}
      id='greeting'
    >
      <Trans i18nKey='Components.Greeting.Hello' />
      &nbsp;
      <Typography
        component='span'
        variant='h1'
        sx={{
          color: 'white !important',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
        }}
      >
        {props.nickname}
      </Typography>
      <br />
      <Trans i18nKey='Components.Greeting.Welcome' />
    </Typography>
  );
}
