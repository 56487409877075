import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Trans } from 'react-i18next';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Roles } from 'types';

import { BurgerItems } from './BurgerItems';
import { AdminItems } from './AdminItems';

export function BurgerMenu() {
  const auth = useAuth();
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = () => () => {
    setDrawer(!drawer);
  };

  function hasAnyRole(allowed: Array<string>) {
    if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.length > 0 && roles?.some((r) => allowed.includes(r));
    }
    return false;
  }

  return (
    <>
      <IconButton
        size='large'
        edge='start'
        aria-label='menu'
        sx={{ mr: 2, color: 'white.main' }}
        onClick={toggleDrawer()}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor='left'
        open={drawer}
        onClose={() => setDrawer(false)}
        variant='temporary'
        PaperProps={{
          sx: {
            backgroundColor: 'secondary.main',
          },
        }}
      >
        <Box>
          <Typography variant='h5' component='div' sx={{ margin: '20px' }}>
            <Trans i18next='Components.Navigation.Title' />
          </Typography>
        </Box>
        <BurgerItems onClick={() => setDrawer(false)} />

        {hasAnyRole(['ADMIN', 'DRAPERY', 'APPROVER']) && (
          <AdminItems onClick={() => setDrawer(false)} />
        )}
        {hasAnyRole(['ADMIN', 'DRAPERY', 'APPROVER', 'PALADIN']) && (
          <AdminItems onClick={() => setDrawer(false)} />
        )}
      </Drawer>
    </>
  );
}
