import { ReactNode } from 'react';
import {ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {DoubleArrow} from '@mui/icons-material';

type Props = {
    text: string
}

export function RulesListItem(props: Readonly<Props>): ReactNode {
  return (
    <ListItem sx={{padding: '0 0 0 16px', '& .MuiListItemText-primary': {fontSize: '20px', fontFamily: 'Neuton'}}}>
      <ListItemIcon sx={{color: '#ffffff', marginRight: '-20px'}}><DoubleArrow /></ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </ListItem>
  );
}