import { useContext, ReactNode } from 'react';
import { SelectChangeEvent, Grid } from '@mui/material';
import { t } from 'i18next';

import { SelectField } from 'components/ui';

import { useFetch } from 'func';
import { CharacterContext } from 'contexts';
import { THESTRAL_URL } from 'const';

type Props = {
  readonly: boolean
}

export function TeacherSubject(props: Readonly<Props>) : ReactNode {
    const {char, setChar} = useContext(CharacterContext);

    const subjects = useFetch<string[]>(`${THESTRAL_URL}/subjects`);

    const changeSelectField = (event: SelectChangeEvent, key: string) => {
      const value = event.target.value;
      const newChar = {...char, [key]: value};
      setChar(newChar);
    };

  return (
    <Grid item xs={12}>
      {subjects && 
      <SelectField
        id='subject'
        readonly={props.readonly}
        label={t('Components.NonPlayerCharacters.Teacher.Subject')}
        list={subjects.data}
        value={[char?.subject]}
        i18nKey='Enum.Subject'
        onChange={(event) => changeSelectField(event, 'subject')}
      />
      }
    </Grid>
  );
}