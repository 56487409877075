import { useEffect, useState, useContext, ReactNode, useRef } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Typography,
  DialogContentText,
} from '@mui/material';
import NoPortrait from 'assets/images/no_portrait.png';

import { Button, Dialog } from 'components/ui';
import {
  PlayerCharacterCard,
  NonPlayerCharacterCard,
  CardCircularProgress,
} from 'components/thestral';

import { CharacterContext, UserContext } from 'contexts';
import { CHARACTERS_URL, HTTP_METHOD, PEOPLE_URL } from 'const';
import { useFetchFromBackend, useSnackbar } from 'func';
import { Character } from 'types';

type Props = {
  fetchChars: () => void;
};

export function CharacterCardItem(props: Readonly<Props>): ReactNode {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();

  const [smallChanges, setSmallChanges] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [closeConfirm, setCloseConfirm] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const { user } = useContext(UserContext);
  const { char, setChar } = useContext(CharacterContext);
  const refChar = useRef<Character>();

  useEffect(() => {
    setChar({ ...char, profileId: user?.profile.id });
    refChar.current = { ...char, profileId: user?.profile.id };
    setLoading(false);
  }, [user?.profile.id]);

  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const FULL_PATH = char?.imagePath;
  const PORTRAIT_URL = STATIC_URL + '/uploads/' + FULL_PATH;

  function houseColor(house: string) {
    switch (house) {
      case 'GRYFFINDOR':
        return '#B40000';
      case 'RAVENCLAW':
        return '#5f787b';
      case 'SLYTHERIN':
        return '#006207';
      case 'HUFFLEPUFF':
        return '#eab354';
    }
    return 'black';
  }

  function saveCharacter(isSubmit: boolean) {
    setLoading(true);
    const type = char.npcType ? '/npcs' : '';
    const body = JSON.stringify({
      ...char,
      profileId: user?.profile.id,
      smallChanges,
      active: true,
    });
    const submit = isSubmit ? '/submit' : '';

    fetchFromBackend(`${CHARACTERS_URL}${type}/${char.id}${submit}`, {
      method: isSubmit ? HTTP_METHOD.PATCH : HTTP_METHOD.PUT,
      body,
    })
      .then((response) => {
        if (char?.newPortrait) {
          saveNewPortrait(char);
        } else {
          const severity = response.status < 400 ? 'success' : 'error';
          const result = response.ok
            ? t('Generic.Successful')
            : `${t('Generic.Failed')} (${response.status})`;

          if (response.ok) {
            props.fetchChars();
          }

          showSnackbar(
            `${t('Components.Characters.Saved')} ${result}`,
            severity
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });

    closeAll();
  }

  function saveNewPortrait(char: Character) {
    const body = new FormData();
    body.append('uploadFile', char?.newPortrait);
    const url = `${PEOPLE_URL}/${char?.id}/portrait`;

    fetchFromBackend(url, { method: HTTP_METHOD.POST, body })
      .then((response) => {
        const severity = response.status < 400 ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        if (response.ok) {
          props.fetchChars();
        }

        showSnackbar(`${t('Components.Characters.Saved')} ${result}`, severity);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function closeDialog() {
    if (JSON.stringify(refChar.current) !== JSON.stringify(char)) {
      setCloseConfirm(true);
    } else {
      setOpen(false);
    }
  }

  function cancelAll() {
    setChar(refChar.current);
    closeAll();
  }

  function closeAll() {
    setCloseConfirm(false);
    setOpen(false);
  }

  function actions(): ReactNode {
    return (
      <>
        {['CREATED', 'DECLINED'].includes(char.state) && (
          <Button
            onClick={() => saveCharacter(true)}
            color='secondary'
            text={<Trans i18nKey='Generic.Submit' />}
          />
        )}

        {!['CREATED', 'DECLINED'].includes(char.state) && (
          <MenuItem
            sx={{
              backgroundColor: '#1e1e1e',
              color: '#fff',
              fontFamily: 'Neuton',
              margin: '0px',
              marginLeft: '-8px',
              '@media (max-width:600px)': {
                fontSize: '1rem',
                padding: '5px',
              },

              '&:hover': {
                backgroundColor: '#303030',
              },
              maxWidth: '200px',
            }}
            onClick={() => setSmallChanges(!smallChanges)}
          >
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  color: 'primary.main',
                },
              }}
              checked={smallChanges}
            />
            <ListItemText
              primary={t('Components.Characters.SmallChanges')}
              sx={{
                margin: '0px',
                '@media (max-width:600px)': {
                  fontSize: '1rem',
                },
              }}
            />
          </MenuItem>
        )}
        <Button
          onClick={() => saveCharacter(false)}
          color='success'
          text={<Trans i18nKey='Generic.Save' />}
          sx={{
            '@media (max-width:400px)': {
              marginTop: '10px',
            },
          }}
        />
      </>
    );
  }

  return (
    <>
      <Dialog
        open={closeConfirm}
        onClose={cancelAll}
        title={t('Generic.EditFoundTitle')}
        content={
          <DialogContentText sx={{ color: '#fff', fontFamily: 'Neuton' }}>
            <Trans i18nKey='Generic.EditFound' />
          </DialogContentText>
        }
        actions={
          <Button
            onClick={() => saveCharacter(false)}
            color='success'
            text={<Trans i18nKey='Generic.SaveAndClose' />}
          />
        }
      />

      <Card
        elevation={0}
        sx={{
          backgroundColor: 'unset',
          paddingTop: '20px',
          transition: '0.3s cubic-bezier(0.25, 0.8, 0.5, 1)',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
        onClick={() => setOpen(true)}
      >
        <CardMedia
          sx={{
            height: '180px',
            margin: 'auto',
            width: '65%',
            paddingTop: '10px',
            cursor: 'pointer',
          }}
          image={FULL_PATH ? PORTRAIT_URL : NoPortrait}
        />
        <CardContent>
          <Typography
            variant='h5'
            gutterBottom
            color={houseColor(char?.house)}
            sx={{ textAlign: 'center', marginBottom: '0' }}
          >
            {char?.name}
          </Typography>
          {char?.npcType && (
            <Typography
              variant='h6'
              sx={{ color: '#9e9e9e', textAlign: 'center' }}
            >
              {t('Enum.NpcType.' + char?.npcType)}
            </Typography>
          )}
          {!char?.npcType && (
            <Typography
              variant='h6'
              sx={{ color: '#9e9e9e', textAlign: 'center' }}
            >
              <Trans i18nKey='Components.PlayerCharacters.TitleSingle' />
            </Typography>
          )}
        </CardContent>
      </Card>

      {open && (
        <Dialog
          sx={{
            '& .MuiPaper-root': {
              bgcolor: '#1e1e1e',
              marginBottom: '0',
            },
          }}
          open={open}
          onClose={closeDialog}
          title={char?.name}
          content={
            <>
              {loading && <CardCircularProgress />}
              {char.npcType && !loading ? (
                <NonPlayerCharacterCard refChar={refChar} />
              ) : (
                <PlayerCharacterCard refChar={refChar} />
              )}
            </>
          }
          actions={actions()}
        />
      )}
    </>
  );
}
