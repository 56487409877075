import { ApprovalStatus, ThestralDataGrid } from 'components/thestral';
import { t } from 'i18next';
import { Approvals, User } from 'types';

type Props = {
  loading: boolean
  approvers: User[]
  approvalData: Approvals
  onRowClick: (elem) => void
}

export function ApprovalDataGrid(props: Readonly<Props>) {
  return (
    <ThestralDataGrid 
      rows={props.approvers}
      columns={[
        {field: 'name', headerName: t('Generic.Name'), sortable: true, width: 300,
            valueGetter: (_, row) => 
              row?.profile.nickname
        },
        {
          field: 'approve', headerName: '', sortable: false, flex: 1, align: 'right', headerAlign: 'right', 
          renderCell: (params) => {
            const state = props.approvalData?.approvals[params.row.id];
            return (<ApprovalStatus state={state} />);
          }
        }
      ]}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 }
        }
      }}
      rowHeight={48}
      hideSearch
      hideFooter
      hideFooterPagination
      pageSizeOptions={[5, 10]}
      loading={props.loading}
      onRowClick={props.onRowClick}
    />
  );
}
