import { useContext, ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import { t } from 'i18next';

import { CardSectionTitle, TextField } from 'components/ui';

import { CharacterContext } from 'contexts';
import { TEXT_FIELD_BIG } from 'const';

type Props = {
    readonly: boolean
}

export function Family(props: Readonly<Props>) : ReactNode {
    const {char, setChar} = useContext(CharacterContext);

    return (
        <Box
            sx={{
                marginTop: 0,
                marginLeft: 0,
                paddingBottom: '30px'
            }}
        >
            <CardSectionTitle id='Generic.Family' />
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <TextField
                        readonly={props.readonly}
                        id='char.familyHistory'
                        defaultValue={char?.familyHistory}
                        multiline
                        label={t('Components.PlayerCharacters.FamilyHistory')}
                        rows={10}
                        onChange={(event) => setChar({...char, familyHistory: event.target.value})}
                        helperText={`${char?.familyHistory?.length ?? 0}/${TEXT_FIELD_BIG}`}
                        error={char?.familyHistory?.length > TEXT_FIELD_BIG}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
