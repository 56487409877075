import { Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import { t } from 'i18next';

import { Button, Dialog, SelectField } from 'components/ui';

import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { HTTP_METHOD, THESTRAL_URL, USERS_URL } from 'const';
import { useEffect, useState } from 'react';
import { Roles } from 'types';

type Props = {
  open: boolean,
  onClose: () => void,
  userId: number
}

export function RolesDialog(props: Readonly<Props>) {
  const [loading, setLoading] = useState<boolean>(true);
  const fetchFromBackend = useFetchFromBackend();

  const {data, loading: fetchLoading, triggerRefetch} = useFetch<Roles>(`${USERS_URL}/${props.userId}/roles`);
  const {data: allRoles} = useFetch<Roles>(`${THESTRAL_URL}/roles`);
  const [roles, setRoles] = useState<Roles>();
  const {showSnackbar} = useSnackbar();

  useEffect(() => {
    if (data) {
      setLoading(false);
      setRoles(data);
    }
  }, [data, fetchLoading]);

  useEffect(() => {
    if (!props.open) {
      triggerRefetch();
    }
  }, [props.open]);

  const onSave = () => {
    setLoading(true);
    const body = JSON.stringify({roles});
    fetchFromBackend(`${USERS_URL}/${props.userId}/roles`, {
      method: HTTP_METHOD.PATCH,
      body
    }).then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;

          showSnackbar(`${t('Generic.Roles')} ${result}`, severity);
        }
      )
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (event: SelectChangeEvent) => {
    const stringArr = event.target.value as unknown;
    const newRoles = stringArr as Roles;
    setRoles(newRoles);
  };

  const rolesFilter = [
    'default-roles-thestral-dev',
    'default-roles-thestral-stage',
    'default-roles-thestral-prod',
    'NoHouse'
  ];

  return (
    <Dialog 
      open={props.open}
      onClose={props.onClose}
      title={t('Generic.Roles')}
      content={loading ? 
          <Box sx={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
            <CircularProgress /> 
          </Box> 
        : 
          <SelectField
            id='roles'
            label={t('Component.Admin.Users.Roles')}
            value={roles || []}
            list={allRoles?.filter((r) => !rolesFilter.includes(r))}
            multiple
            i18nKey='Enum.Roles'
            onChange={onChange}
          />
      }
      actions={
        !loading && 
          <Button 
            onClick={onSave}
            color='success' 
            sx={{ marginLeft: '5px' }} 
            text={t('Components.Admin.Users.AssignRoles')} 
          />
      }
    />
  );
}