import { ReactNode } from 'react';
import { t } from 'i18next';

import { PlayerCharacterCard } from 'components/thestral';

import { Characters } from '../Characters';

export function PlayerCharacters(): ReactNode {
  return (
    <Characters 
      title={t('Components.PlayerCharacters.Title')}
      dialogContent={<PlayerCharacterCard readonly/>}
    />
  );
}