import { ReactNode } from 'react';
import { t } from 'i18next';
import { Box, Grid } from '@mui/material';

import { Headline, RulesBox, RulesContent } from 'components/thestral';

import ImageRuleIT from 'assets/images/rules_it.jpg';
import ImageRuleDrapery from 'assets/images/rules_drapery.jpg';
import ImageRuleOT from 'assets/images/rules_ot.jpg';
import ImageRuleWorld from 'assets/images/rules_world.jpg';

export function Rules(): ReactNode {
  const title = t('Components.Rules.Title');
  const breadcrumbs = [{ href: '/', title: t('Generic.Start') }];

  return (
    <Box
      className='content'
      sx={{
        marginTop: '50px',
        marginBottom: '50px',
        width: '100%',
        '@media (max-width:600px)': {
          marginTop: '20px',
        },
      }}
    >
      <Headline title={title} breadcrumbs={breadcrumbs} />

      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <RulesBox
          image={ImageRuleWorld}
          content={
            <RulesContent
              title={t('Components.Rules.WorldBuilding.Title')}
              text={t('Components.Rules.WorldBuilding.Text')}
              category='IT'
              to='/rules/world-building'
            />
          }
          reversed
        />
        <RulesBox
          image={ImageRuleIT}
          content={
            <RulesContent
              title={t('Components.Rules.InTimeRules.Title')}
              text={t('Components.Rules.InTimeRules.Text')}
              category='IT'
              to='/rules/it'
            />
          }
        />
        <RulesBox
          image={ImageRuleDrapery}
          content={
            <RulesContent
              title={t('Components.Rules.Drapery.Title')}
              text={t('Components.Rules.Drapery.Text')}
              category='IT'
              to='/rules/drapery'
            />
          }
          reversed
        />
        <RulesBox
          image={ImageRuleOT}
          content={
            <RulesContent
              title={t('Components.Rules.CodeOfConduct.Title')}
              text={t('Components.Rules.CodeOfConduct.Text')}
              category='OT'
              to='/rules/code-of-conduct'
            />
          }
        />
      </Grid>
    </Box>
  );
}
