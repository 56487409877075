import { Box } from '@mui/material';

import { GridHeadline } from 'components/thestral';
import { t } from 'i18next';

export function AdminClubs() {
  return (
    <Box className='content' sx={{margin: '75px auto 0 auto', maxWidth: '900px'}}>
      <GridHeadline title={t('Generic.WiP')} />
    </Box>
  );
}