import {useContext, ReactNode} from 'react';
import {Box, Grid, SelectChangeEvent} from '@mui/material';
import { t } from 'i18next';

import { CardSectionTitle, SelectField, TextField } from 'components/ui';

import { Club } from 'types';
import { useFetch } from 'func';
import { CharacterContext } from 'contexts';
import { CLUBS_URL, THESTRAL_URL } from 'const';

type Props = {
  readonly: boolean
}

export function School(props: Readonly<Props>) : ReactNode {
    const {char, setChar} = useContext(CharacterContext);

    const duties = useFetch<string[]>(`${THESTRAL_URL}/schoolduties`);
    const quidditch = useFetch<string[]>(`${THESTRAL_URL}/quidditch`);
    const subjects = useFetch<string[]>(`${THESTRAL_URL}/subjects`);
    const benefits = useFetch<string[]>(`${THESTRAL_URL}/benefits`);
    const plots = useFetch<string[]>(`${THESTRAL_URL}/plots`);

    const clubs = useFetch<Club[]>(CLUBS_URL);

    const changeSelectField = (event: SelectChangeEvent, key: string) => {
      let value = null;
      if (key === 'clubs') {
        value = clubs.data?.filter((club) => event.target.value.indexOf(club.name) > -1);
      } else {
        value = event.target.value;
      }
      const newChar = {...char, [key]: value};
      setChar(newChar);
    };

    return (
        <Box
            sx={{
                marginTop: 0,
                marginLeft: 0,
                paddingBottom: '30px'
            }}
        >
            <CardSectionTitle id='Generic.School' />
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={6}>
                    {duties &&
                      <SelectField
                        readonly={props.readonly}
                        id='duty'
                        label='Ämter'
                        value={char?.schoolDuties}
                        i18nKey='Enum.SchoolDuty'
                        list={duties.data}
                        disabled={char?.new}
                        multiple
                        onChange={(event) => changeSelectField(event, 'schoolDuties')}
                      />
                    }
                </Grid>
                <Grid item xs={6}>
                    {quidditch &&
                      <SelectField
                        readonly={props.readonly}
                        id='quidditch'
                        label='Interesse am Quidditchauswahltraining deines Hauses'
                        value={[char?.quidditch]}
                        i18nKey='Enum.Quidditch'
                        list={quidditch.data}
                        onChange={(event) => changeSelectField(event, 'quidditch')}
                      />
                    }
                </Grid>

                <Grid item xs={6}>
                    {subjects &&
                      <SelectField
                        readonly={props.readonly}
                        id='favorite'
                        label='Lieblingsfach'
                        value={[char?.favoriteSubject]}
                        i18nKey='Enum.Subject'
                        list={subjects.data}
                        onChange={(event) => changeSelectField(event, 'favoriteSubject')}
                      />
                    }
                </Grid>
                <Grid item xs={6}>
                    {subjects &&
                      <SelectField
                        readonly={props.readonly}
                        id='leastFavorite'
                        label='Hassfach'
                        value={[char?.leastFavoriteSubject]}
                        i18nKey='Enum.Subject'
                        list={subjects.data}
                        onChange={(event) => changeSelectField(event, 'leastFavoriteSubject')}
                      />
                    }
                </Grid>

                <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
                    <TextField
                        readonly={props.readonly}
                        id='pet'
                        defaultValue={char?.pet}
                        value={char?.pet}
                        label={t('Components.PlayerCharacters.Pet')}
                        onChange={(event) => setChar({...char, pet: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
                    <TextField
                        readonly={props.readonly}
                        id='wand'
                        defaultValue={char?.wand}
                        value={char?.wand}
                        label={t('Components.PlayerCharacters.Wand')}
                        onChange={(event) => setChar({...char, wand: event.target.value})}
                    />
                </Grid>

                <Grid item xs={6}>
                    {plots &&
                      <SelectField
                        readonly={props.readonly}
                        id='assignedPlot'
                        label='Projektgruppe'
                        value={[char?.assignedPlot]}
                        i18nKey='Enum.Plot'
                        list={plots?.data?.filter((p) => p != 'NOPLOT')}
                        onChange={(event) => changeSelectField(event, 'assignedPlot')}
                      />
                    }
                </Grid>
                <Grid item xs={6}>
                    {clubs &&
                      <SelectField
                        readonly={props.readonly}
                        id='clubs'
                        label='K.L.U.B.S'
                        value={char?.clubs?.map((c) => c.name) || []}
                        list={clubs.data?.map((c) => c.name)}
                        multiple
                        onChange={(event) => changeSelectField(event, 'clubs')}
                      />
                    }
                </Grid>
                <Grid item xs={6}>
                    {benefits &&
                      <SelectField
                        readonly={props.readonly}
                        id='benfits'
                        label='B.E.N.E.F.I.T.S'
                        value={[char?.benefit]}
                        list={benefits.data}
                        i18nKey='Enum.Benefits'
                        onChange={(event) => changeSelectField(event, 'benefit')}
                      />
                    }
                </Grid>
            </Grid>
        </Box>
    );
}
