import { ReactNode } from 'react';
import { Box, Grid } from '@mui/material';

type Props = {
  image: string;
  content: ReactNode;
  reversed?: boolean;
};

export function RulesBox(props: Readonly<Props>): ReactNode {
  const isReversed = props.reversed;
  const content = (
    <Box
      component='img'
      sx={{
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      }}
      src={props.image}
      alt='RulesBox Image'
    />
  );

  return (
    <>
      <Grid
        container
        sx={{
          '@media (max-width:600px)': {
            display: 'none',
          },
        }}
      >
        <Grid
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '15px',
            '@media (max-width:600px)': {
              padding: '10px',
            },
          }}
        >
          {isReversed ? props.content : content}
        </Grid>
        <Grid
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '15px',
            '@media (max-width:600px)': {
              padding: '10px',
            },
          }}
        >
          {isReversed ? content : props.content}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          '@media (min-width:601px)': {
            display: 'none',
          },
        }}
      >
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '15px',
            height: '150px',
          }}
        >
          {content}
        </Grid>
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '15px',
          }}
        >
          {props.content}
        </Grid>
      </Grid>
    </>
  );
}
