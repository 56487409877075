import { useState } from 'react';
import { Box, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { Send } from 'mdi-material-ui';
import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';

import { ThestralDataGrid, ApprovalStatus } from 'components/thestral';
import { TextField } from 'components/ui';

import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import {
  HTTP_METHOD,
  STATIC_URL,
  EMPTY,
  ROLES_URL,
  DRAPERIES_URL,
} from 'const';
import { User, DraperyCheck, Approvals } from 'types';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Search } from '@mui/icons-material';

type Props = {
  drapery: DraperyCheck;
  triggerRefetch: () => void;
};

export function DraperyDetail(props: Readonly<Props>) {
  const auth = useAuth();
  const { data, loading } = useFetch<User[]>(`${ROLES_URL}/DRAPERY`);
  const {
    data: approvalsData,
    triggerRefetch: approvalsFetch,
    loading: approvalsLoading,
  } = useFetch<Approvals>(`${DRAPERIES_URL}/${props.drapery.id}/approval`);
  const fetchFromBackend = useFetchFromBackend();

  const [note, setNote] = useState(props.drapery?.note);
  const { showSnackbar } = useSnackbar();

  const onRowClick = (elem) => {
    if (auth.user?.profile.email !== elem.row.email) {
      return;
    }
    const state = approvalsData?.approvals[elem.row.id];
    approve(!state);
  };

  function approve(newState: boolean) {
    fetchFromBackend(
      `${DRAPERIES_URL}/${props.drapery.id}/approval?approval=${newState}`,
      { method: HTTP_METHOD.PUT }
    )
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          approvalsFetch();
          props.triggerRefetch();
        }

        showSnackbar(`${t('Generic.ApprovalChange')} ${result}`, severity);
      })
      .catch((error) => console.error(error));
  }

  const onUpdateNote = () => {
    const body = JSON.stringify({ note });
    fetchFromBackend(`${DRAPERIES_URL}/${props.drapery?.id}`, {
      method: HTTP_METHOD.PATCH,
      body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          props.triggerRefetch();
        }

        showSnackbar(
          `${t('Components.Drapery.NoteUpdate')} ${result}`,
          severity
        );
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CardMedia
          component='img'
          sx={{ maxHeight: '500px', objectFit: 'contain' }}
          image={`${STATIC_URL}/uploads/${props.drapery?.imagePath}`}
          alt='Thumbnail'
        />
        <IconButton
          sx={{ alignItems: 'start' }}
          href={`${STATIC_URL}/uploads/${props.drapery?.imagePath}`}
          target='_blank'
        >
          <Search color='primary' />
        </IconButton>
      </Box>

      <Box
        sx={{
          '& .MuiIconButton-root:hover': {
            backgroundColor: 'rgba(0,0,0,0)',
          },
        }}
      >
        <TextField
          defaultValue={props.drapery?.note}
          multiline
          rows={5}
          sx={{
            width: '100%',
          }}
          label={t('Generic.Note')}
          onChange={(event) => setNote(event.target.value)}
        />

        <IconButton
          onClick={() => onUpdateNote()}
          color='primary'
          sx={{
            float: 'right',
            justifyContent: 'end',
            alignItems: 'end',
            display: 'flex',
          }}
        >
          <Send />
        </IconButton>
      </Box>

      {props.drapery && approvalsData != null && (
        <Grid item xs={12}>
          <Typography
            component='div'
            variant='body1'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '25px',
            }}
          >
            {t('Generic.Approvals')}
          </Typography>
          <ThestralDataGrid
            rows={data || EMPTY}
            onRowClick={onRowClick}
            hideSearch
            sx={{
              minWidth: '500px',
              '& .MuiDataGrid-columnHeaders': {
                display: 'none',
              },
            }}
            columns={[
              {
                field: 'name',
                headerName: t('Generic.Name'),
                sortable: true,
                flex: 1,
                valueGetter: (_, row) => row.profile?.nickname,
              },
              {
                field: 'approve',
                headerName: '',
                sortable: false,
                align: 'right',
                headerAlign: 'right',
                renderCell: (params: GridRenderCellParams) => {
                  const state = approvalsData?.approvals[params.row.id];
                  return approvalsLoading ? (
                    <></>
                  ) : (
                    <ApprovalStatus state={state} />
                  );
                },
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            hideFooter
            hideFooterPagination
            pageSizeOptions={[5, 10]}
            loading={loading}
          />
        </Grid>
      )}
    </>
  );
}
