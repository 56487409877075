import { Avatar, Chip, Typography } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { HOUSE_FILTER } from 'const';
import { t } from 'i18next';
import { MutableRefObject, ReactNode } from 'react';

type Filter = {
  label?: string;
  avatarColor: string;
  color: string;
  filterModel: GridFilterModel;
  textColor?: string;
};

type Props = {
  data: Filter;
  count?: number;
  icon?: ReactNode;
  onClick?: (elem) => void;
  isActive?: boolean;
  apiRef?: MutableRefObject<GridApiCommunity>;
};

export function ParticipationChip(props: Readonly<Props>) {
  const filter = props.data;

  const onClick = (elem) => {
    props.onClick(elem);
    if (props.isActive) {
      props.apiRef.current.setFilterModel(HOUSE_FILTER[0].filterModel); // Reset to all, if clicked twice
    } else {
      props.apiRef.current.setFilterModel(filter.filterModel);
    }
  };

  return (
    <Chip
      avatar={
        <Avatar sx={{ backgroundColor: filter.avatarColor }}>
          <Typography color='#fff' sx={{ fontFamily: 'Neuton' }}>
            {props.count}
          </Typography>
        </Avatar>
      }
      label={t(filter.label) || props.icon}
      onClick={onClick}
      variant={props.isActive ? 'outlined' : 'filled'}
      sx={{
        fontFamily: 'Neuton',
        backgroundColor: filter.color,
        color: filter.textColor ?? '#fff',
        minWidth: '100px',
        marginRight: '10px',
        '& .MuiChip-label': {
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    />
  );
}
