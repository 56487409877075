import { CSSProperties } from 'react';
import { createTheme, ThemeOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import Westmeath from 'assets/fonts/Westmeath.ttf';
import Bebas from 'assets/fonts/BebasNeue-Regular.ttf';
import Neuton from 'assets/fonts/Neuton-Regular.ttf';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    ruleTitle: true;
    ruleHeadline: true
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  ruleTitle: CSSProperties,
  ruleHeadline: CSSProperties
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Westmeath',
    h1: { 
      fontFamily: 'Westmeath',
      color: '#eab354'
    },
    h4: { fontFamily: 'Bebas' },
    h5: { fontFamily: 'Bebas' },
    h6: { fontFamily: 'Bebas' },
    ruleTitle: {
      paddingTop: '30px', 
      fontFamily: 'Neuton',
      fontWeight: '700',
      lineHeight: '1.5', 
      color: '#eab354'
    },
    ruleHeadline: {
      textAlign: 'center', 
      fontFamily: 'Bebas', 
      color: '#e7e4e3'
    },
    caption: { 
      fontSize: '1rem', 
      fontFamily: 'Neuton', 
      fontWeight: '400', 
      lineHeight: '1.375rem', 
      letterSpacing: '0.0071em',
      color: 'rgba(255, 255, 255, 0.7)'
    },
    body1: { fontFamily: 'Bebas'},
    button: { fontFamily: 'Bebas' },
    allVariants: { color: '#ffffff' }
  } as ExtendedTypographyOptions,
  palette: {
    primary: { main: '#eab354' },
    secondary: { main: '#5f787b' },
    error: { main: '#ff5252' },
    white: { main: '#ffffff'}
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Westmeath';
          src: local('Westmeath'), url(${Westmeath}) format('truetype');
        }

        @font-face {
          font-family: 'Bebas';
          src: local('Bebas'), url(${Bebas}) format('truetype');
        }

        @font-face {
          font-family: 'Neuton';
          src: local('Neuton'), url(${Neuton}) format('truetype');
        }
      `
    },
    MuiDataGrid: {
      styledOverrides: {
        root: {
          '--DataGrid-containerBackground': 'transparent'
        }
      }
    }
  } 
} as ThemeOptions);