import { Box, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';

import {
  DeleteDialog,
  AdminViewDialog,
  ThestralDataGrid,
} from 'components/thestral';

import { CHARACTERS_URL, EMPTY } from 'const';
import { useFetch } from 'func';
import { useEffect, useMemo, useState } from 'react';
import { CharacterRevision, Character } from 'types';
import { Delete } from '@mui/icons-material';
import { CharacterContext } from 'contexts';

export function AdminPlayerCharacterGrid() {
  const [loading, setLoading] = useState<boolean>(true);
  const { data, triggerRefetch } =
    useFetch<CharacterRevision[]>(CHARACTERS_URL);
  const [characters, setCharacters] = useState<Character[]>();
  const [char, setChar] = useState<Character>();
  const charContextValue = useMemo(() => ({ char, setChar }), [char, setChar]);

  const [deleteOpen, setDeleteOpen] = useState<boolean>();
  const [viewOpen, setViewOpen] = useState<boolean>(false);

  const theme = useTheme();
  const [colVisModel, setColVisModel] = useState({});
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setColVisModel({
      playerName: !isSm
    });
  }, [isSm]);

  useEffect(() => {
    if (data) {
      const chars = data.reduce((acc, item) => {
        acc.push(item?.playerCharacter);
        return acc;
      }, []);
      setCharacters(chars);
      setLoading(false);
    }
  }, [data]);

  const onDelete = (event, character: Character) => {
    event.stopPropagation();
    setDeleteOpen(true);
    setChar(character);
  };

  const onRowClick = (elem) => {
    setChar(elem?.row);
    setViewOpen(true);
  };

  return (
    <Box>
      <ThestralDataGrid
        rows={characters || EMPTY}
        columnVisibilityModel={colVisModel}
        columns={[
          {
            field: 'name',
            headerName: t('Generic.Name'),
            sortable: true,
            flex: 3,
          },
          {
            field: 'playerName',
            headerName: t('Generic.OtName'),
            sortable: true,
            flex: 1,
          },
          {
            field: 'ageGroup',
            headerName: isSm ? t('Generic.AgeGroupShort') : t('Generic.AgeGroup'),
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            valueGetter: (_, row) => {
              const ageGroup = t(`Enum.AgeGroup.${row.ageGroup}`);
              return !row.ageGroup ? '' : (isSm ? ageGroup[0] : ageGroup);
            }
          },
          {
            field: 'house',
            headerName: t('Components.Characters.House'),
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            valueGetter: (_, row) => {
              const house = t(`Enum.House.${row.house}`);
              return !row.house ? '' : (isSm ? house[0] : house);
            }
          },
          {
            field: 'actions',
            headerName: 'Aktionen',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params: GridRenderCellParams) => {
              return loading ? (
                <></>
              ) : (
                <IconButton onClick={(event) => onDelete(event, params.row)}>
                  <Tooltip title={t('Generic.DeleteUser')}>
                    <Delete color='primary' />
                  </Tooltip>
                </IconButton>
              );
            },
          },
        ]}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        onRowClick={onRowClick}
      />

      {char && (
        <DeleteDialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          triggerRefetch={triggerRefetch}
          endpoint={`${CHARACTERS_URL}/${char?.id}`}
          i18nBase='Components.Admin.Character'
        />
      )}

      <CharacterContext.Provider value={charContextValue}>
        {char && (
          <AdminViewDialog
            open={viewOpen}
            onClose={() => setViewOpen(false)}
            character={char}
          />
        )}
      </CharacterContext.Provider>
    </Box>
  );
}
