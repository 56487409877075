import { useEffect, useState } from 'react';
import { t } from 'i18next';

import { Button, Dialog } from 'components/ui';

import { Event, Owlpost } from 'types';
import { HTTP_METHOD, EMPTY, OWLPOST_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { Shuffle, Telegram } from '@mui/icons-material';
import { Owl, PrinterOutline } from 'mdi-material-ui';
import { ThestralDataGrid } from '../ThestralDataGrid';
import { Box, CircularProgress } from '@mui/material';

type Props = {
  event: Event;
  open: boolean;
  onClose: () => void;
};

export function OwlpostDialog(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();
  const { data, triggerRefetch } = useFetch<Owlpost>(
    `${OWLPOST_URL}/${props.event?.owlpostId}`
  );
  const [owlpost, setOwlpost] = useState<Owlpost>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (data) {
      setOwlpost(data);
      setLoading(false);
    }
  }, [data]);

  const backendCall = (type: string) => {
    setLoading(true);
    fetchFromBackend(`${OWLPOST_URL}/${owlpost.id}/${type}`, {
      method: type == 'shuffle' ? HTTP_METHOD.POST : HTTP_METHOD.PUT,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          triggerRefetch();
        }

        let i18nKey = '';
        switch (type) {
          case 'open':
            i18nKey = 'OpenResponse';
            break;
          case 'close':
            i18nKey = 'CloseResponse';
            break;
          case 'shuffle':
            i18nKey = 'ShuffleResponse';
            break;
          case 'activate':
            i18nKey = 'ActivateResponse';
            break;
        }
        showSnackbar(
          `${t('Components.Admin.Events.Owlpost.' + i18nKey)} ${result}`,
          severity
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    setLoading(true);
    props.onClose();
  };

  const actions = () => {
    if (owlpost?.sent) {
      return <></>;
    } else if (owlpost?.shuffles.length > 0) {
      return (
        <Button
          onClick={() => backendCall('activate')}
          color='success'
          text={t('Components.Admin.Events.Owlpost.SendOut')}
          startIcon={<Telegram />}
        />
      );
    } else if (owlpost?.registrationOpen) {
      return (
        <Button
          onClick={() => backendCall('close')}
          color='error'
          text={t('Components.Admin.Events.Owlpost.Close')}
        />
      );
    } else if (owlpost?.enrollments.length > 0) {
      return (
        <>
          <Button
            onClick={() => backendCall('shuffle')}
            color='success'
            text={t('Components.Admin.Events.Owlpost.Shuffle')}
            startIcon={<Shuffle />}
          />
          <Button
            onClick={() => backendCall('open')}
            color='warning'
            text={t('Components.Admin.Events.Owlpost.ReOpen')}
            startIcon={<Owl />}
          />
        </>
      );
    }
    return (
      <Button
        onClick={open}
        color='success'
        text={t('Components.Admin.Events.Owlpost.Open')}
        startIcon={<Owl />}
      />
    );
  };

  const print = () => {
    const entries = owlpost?.shuffles.map((shuffle) => ({
      name: shuffle.sender,
      dayOne: `${shuffle.dayOne.name} (${shuffle.dayOne.characterName})`,
      dayTwo: `${shuffle.dayTwo.name} (${shuffle.dayTwo.characterName})`,
    }));
    entries.sort((a, b) => {
      if (a.name < b.name) return 1;
      if (a.name > b.name) return -1;
      return 0;
    });
    const a = window.open('', '', 'width=1200');
    const headers = [
      t('Generic.Name'),
      t('Components.Admin.Events.Owlpost.DayOne'),
      t('Components.Admin.Events.Owlpost.DayOne'),
    ];

    let tableHtml =
      '<html><head><style>@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; }} th, td {border-right: 1px solid black; padding: 8px; text-align: left} th {background-color: #f2f2f2} tr:nth-child(even){background-color: #f9f9f9}</style></head><body><table style="border 1px solid black; border-collapse: collapse; width: 100%">';
    tableHtml += `<h1 align="center">${t(
      'Components.Admin.Events.Owlpost.PrintHeadline'
    )} ${props.event?.name}</h1>`;
    tableHtml += '<thead><tr>';
    headers.forEach(
      (header) => (tableHtml += `<th align="left">${header}</th>`)
    );
    tableHtml += '</tr></thead><tbody>';

    entries.forEach((entry) => {
      tableHtml += '<tr style="border-right: 1px solid red;">';
      tableHtml += `<td >${entry.name || ''}</td>`;
      tableHtml += `<td >${entry.dayOne || ''}</td>`;
      tableHtml += `<td >${entry.dayTwo || ''}</td>`;
      tableHtml += '</tr>';
    });

    tableHtml += '</tbody></table></body></html>';

    a.document.write(tableHtml);
    a.document.close();

    a.print();
    setTimeout(() => a.close(), 1);
  };

  const content = () => {
    return (
      <Box sx={{ marginTop: '15px' }}>
        {owlpost?.sent && (
          <Button
            onClick={print}
            color='warning'
            startIcon={<PrinterOutline />}
            text={t('Components.Admin.Events.Owlpost.Print')}
          />
        )}
        {grid()}
      </Box>
    );
  };

  const grid = () => {
    if (owlpost?.shuffles.length > 0) {
      return (
        <ThestralDataGrid
          rows={owlpost?.shuffles || EMPTY}
          sx={{ height: 'auto', width: '100%' }}
          columns={[
            {
              field: 'sender',
              headerName: t('Generic.OtName'),
              sortable: true,
              maxWidth: 90,
              flex: 1,
            },
            {
              field: 'dayOne.name',
              headerName: t('Components.Admin.Events.Owlpost.DayOne'),
              sortable: true,
              flex: 1,
              valueGetter: (_, row) =>
                `${row.dayOne?.name} (${row.dayOne?.characterName})`,
            },
            {
              field: 'dayTwo.name',
              headerName: t('Components.Admin.Events.Owlpost.DayTwo'),
              sortable: true,
              flex: 1,
              valueGetter: (_, row) =>
                `${row.dayTwo?.name} (${row.dayTwo?.characterName})`,
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          loading={loading}
        />
      );
    } else if (owlpost?.enrollments.length > 0) {
      return (
        <ThestralDataGrid
          rows={owlpost?.enrollments || EMPTY}
          sx={{ height: 'auto', width: '100%' }}
          columns={[
            {
              field: 'name',
              headerName: t('Generic.Name'),
              sortable: true,
              maxWidth: 90,
              flex: 1,
            },
            {
              field: 'dayOne.name',
              headerName: t('Components.Admin.Events.Owlpost.DayOne'),
              sortable: true,
              valueGetter: () => '-',
              flex: 1,
            },
            {
              field: 'dayTwo.name',
              headerName: t('Components.Admin.Events.Owlpost.DayTwo'),
              sortable: true,
              valueGetter: () => '-',
              flex: 1,
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          loading={loading}
        />
      );
    }

    return <></>;
  };

  const caption = () => {
    if (owlpost?.sent) {
      return t('Components.Admin.Events.Owlpost.Sent');
    } else if (owlpost?.shuffles.length > 0) {
      return t('Components.Admin.Events.Owlpost.ShuffledNotSent');
    } else if (owlpost?.registrationOpen) {
      return t('Components.Admin.Events.Owlpost.RegistrationOpen');
    } else if (owlpost?.enrollments.length > 0) {
      return t('Components.Admin.Events.Owlpost.ClosedNotShuffled');
    }

    return t('Components.Admin.Events.Owlpost.RegistrationNotOpen');
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      title={`${props.event?.name} // ${t(
        'Components.Admin.Events.Owlpost.Title'
      )}`}
      actions={loading ? actions() : <></>}
      content={
        loading ? (
          <Box display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        ) : (
          content()
        )
      }
      caption={caption()}
    />
  );
}
