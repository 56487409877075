import { ReactNode } from 'react';
import { Typography } from '@mui/material';

import { Breadcrumbs } from 'components/ui';

import { CustomLink } from 'types';

type Props = {
  title: string;
  breadcrumbs?: CustomLink[];
  marginBottom?: string;
};

export function Headline(props: Readonly<Props>): ReactNode {
  return (
    <>
      {props.breadcrumbs && (
        <Breadcrumbs site={props.title} links={props.breadcrumbs} />
      )}
      <Typography
        variant='h1'
        component='p'
        sx={{
          fontSize: '3rem',
          fontWeight: '700',
          marginBottom: props.marginBottom ?? '60px',
          textAlign: 'left',
          lineHeight: '40px',
          '@media (max-width:600px)': {
            fontSize: '2.8rem',
            lineHeight: '50px',
            margin: '0px 0 30px 0px',
          },
          '@media (max-width:400px)': {
            fontSize: '2rem',
            lineHeight: '40px',
            margin: '0px 0 20px 0',
          },
        }}
      >
        {props.title}
      </Typography>
    </>
  );
}
