import { MutableRefObject, ReactNode, useContext, useEffect } from 'react';
import { Box } from '@mui/material';

import { Family } from './Family';
import { General } from './General';
import { School } from './School';
import { Interests } from './Interests';
import { WarTime } from './WarTime';
import { Other } from './Other';

import { useFetch } from 'func';
import { Character, CharacterRevision } from 'types';
import { CharacterContext } from 'contexts';
import { CHARACTERS_URL } from 'const';

type Props = {
    readonly?: boolean,
    refChar?: MutableRefObject<Character>
}

export function PlayerCharacterCard(props: Readonly<Props>): ReactNode {
    const { readonly = false } = props;
    const {char, setChar} = useContext(CharacterContext);
    const {data} = useFetch<CharacterRevision>(`${CHARACTERS_URL}/${char?.id}?readonly=${readonly}`);

    useEffect(() => {
        if (data) {
            setChar(data?.playerCharacter);
            if (props.refChar) {
                props.refChar.current = data?.playerCharacter;
            }
        }
    }, [data]);

    return (
        <Box>
            <General readonly={props.readonly} />
            <Family readonly={props.readonly} />
            <School readonly={props.readonly} />
            <Interests readonly={props.readonly} />
            <WarTime readonly={props.readonly} />
            <Other readonly={props.readonly} />
        </Box>
    );
}