import { ReactNode } from 'react';
import { RadioGroup, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { FormControlLabel } from 'components/ui';

import { FormikProps, FormikValues } from 'formik';

type Props = {
  formik: FormikProps<FormikValues>
}

export function DataProtection(props: Readonly<Props>): ReactNode {
  const formik = props.formik;

  return (
    <>
      <Typography 
        id='radio-data-vis'
        variant='ruleTitle' 
        component='p' 
      >
        <Trans i18nKey='Generic.DataProtection' />
      </Typography>

      <Typography variant='body1' component='p' sx={{ fontFamily: 'Neutron', paddingBottom: '10px' }}>
        <Trans i18nKey='Components.Profile.DataProtectionData.DataProtection.Caption' />
      </Typography>

      <RadioGroup
        id='profile.dataVisibility'
        aria-labelledby='radio-data-vis'
        value={formik.values.profile?.dataVisibility}
        onChange={(event) => formik.setFieldValue('profile.dataVisibility', (!formik.values.readonly ? event.target.value : formik.values.profile?.dataVisibility))}
      >
        <FormControlLabel value='FORORGA' label={t('Components.Profile.DataProtectionData.DataProtection.ForOrga')} />
        <FormControlLabel value='FORALL' label={t('Components.Profile.DataProtectionData.DataProtection.ForAll')} />
      </RadioGroup>
    </>
  );
}
