import { ReactNode } from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';

import { CustomLink } from 'types';

type Props = {
  links: CustomLink[],
  site: string
}

export function Breadcrumbs(props: Readonly<Props>) : ReactNode {
  const links = props.links;

  return (
    <MuiBreadcrumbs 
      aria-label='breadcrumb'
      sx={{
        fontFamily: 'Neuton',
        fontSize: '16px',
        color: 'rgba(255, 255, 255, 0.5)'
      }}
    >
      {links.map((link: CustomLink) => (
        <Link 
          key={link.href}
          underline='hover'
          color='primary.main'
          href={link.href}
        >
          {link.title}
        </Link>
        ))
      }
      {links.length > 0 &&
      <Typography 
        color='text.primary'
        sx={{
          fontFamily: 'inherit',
          fontSize: 'inherit',
          color: 'inherit'
        }}
      >
        {props.site}
      </Typography> 
      }
    </MuiBreadcrumbs>
  );
}