import { useState, useMemo, ReactNode } from 'react';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { Box, Card, Tab } from '@mui/material';

import { Headline } from 'components/thestral';

import { CustomLink as Link, RuleComponent } from 'types';
import { JsonContext } from 'contexts';

type Props = {
  title: string;
  breadcrumbs: Link[];
  items: RuleComponent[];
};

export function RulesTabContext(props: Readonly<Props>): ReactNode {
  const [json, setJson] = useState();

  const jsonContextValue = useMemo(() => ({ json, setJson }), [json, setJson]);
  const [value, setValue] = useState('0');

  return (
    <Box
      className='content'
      sx={{
        width: '100%',
        marginTop: '50px',
        paddingTop: '18px',
        '@media (max-width:600px)': {
          marginTop: '0px',
        },
        '& .MuiTab-root.Mui-selected': {
          color: 'primary.main',
          fontFamily: 'Neuton',
          letterSpacing: '0.0892857143em',
        },
        '& .MuiTab-root': {
          color: 'rgba(255, 255, 255, 0.6)',
          fontFamily: 'Neuton',
          letterSpacing: '0.0892857143em',
        },
        '& .MuiTabScrollButton-root': {
          color: '#ffffff',
        },
        '& .MuiTabs-scrollButtons.Mui-disabled': {
          opacity: 0.3,
        },
      }}
    >
      <Headline title={props.title} breadcrumbs={props.breadcrumbs} />

      <Card sx={{ backgroundColor: '#262b2b' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(_, newValue) => setValue(newValue)}
              textColor='primary'
              variant='scrollable'
              scrollButtons={true}
              allowScrollButtonsMobile
            >
              {props.items?.map((item, i) => (
                <Tab
                  label={item.name}
                  value={i.toString()}
                  disabled={item.disabled}
                  key={item.name + '-tab'}
                />
              ))}
            </TabList>
          </Box>
          <JsonContext.Provider value={jsonContextValue}>
            {props.items?.map((item, i) => (
              <TabPanel key={item.name + '-panel'} value={i.toString()}>
                {item.component}
              </TabPanel>
            ))}
          </JsonContext.Provider>
        </TabContext>
      </Card>
    </Box>
  );
}
