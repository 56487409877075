import { ReactNode } from 'react';
import { Box, List, Link, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import {
  TabHeadline,
  RulesBlockCaption,
  RulesListItem,
} from 'components/thestral';

import { DraperyCheckStep } from './DraperyCheckStep';

import Step1 from 'assets/images/drapery1.png';
import Step2 from 'assets/images/drapery2.png';

export function DraperyCheckTab(): ReactNode {
  return (
    <>
      <TabHeadline title={t('Components.Rules.Drapery.DraperyCheck.Title')} />

      <Box sx={{ padding: '12px' }}>
        <RulesBlockCaption
          caption={t('Components.Rules.Drapery.DraperyCheck.Caption')}
        />

        <Box>
          <Typography
            variant='body1'
            component='p'
            sx={{
              paddingTop: '10px',
              fontFamily: 'Neuton',
              color: 'rgba(255, 255, 255, 0.6)',
              fontSize: '1.2rem',
            }}
          >
            <Trans i18nKey='Components.Rules.Drapery.DraperyCheck.Introduction' />
          </Typography>

          <DraperyCheckStep
            i18nText='Components.Rules.Drapery.DraperyCheck.Step1'
            image={Step1}
          />
          <DraperyCheckStep
            i18nText='Components.Rules.Drapery.DraperyCheck.Step2'
            image={Step2}
          />
          <DraperyCheckStep i18nText='Components.Rules.Drapery.DraperyCheck.Step3' />

          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <Link
              sx={{
                color: 'primary',
                textDecoration: 'underline',
                fontSize: 'xx-large',
              }}
              href='mailto:malkins@thestral-larp.de'
            >
              malkins@thestral-larp.de
            </Link>
          </Box>

          <Box>
            <Box>
              <Typography
                variant='body1'
                component='p'
                sx={{
                  fontFamily: 'Neuton',
                  color: 'rgba(255, 255, 255, 0.6)',
                  fontSize: '1.2rem',
                  letterSpacing: '0.0125em',
                  padding: '10px 0 0 0 ',
                }}
              >
                <Trans
                  i18nKey={t('Components.Rules.Drapery.DraperyCheck.Hints')}
                />
              </Typography>
            </Box>
          </Box>

          <List>
            <RulesListItem text={t('Components.Rules.Drapery.Wands')} />
            <RulesListItem text={t('Components.Rules.Drapery.Bags')} />
            <RulesListItem
              text={t('Components.Rules.Drapery.BoughtSweaters')}
            />
            <RulesListItem text={t('Components.Rules.Drapery.BoughtTies')} />
          </List>
        </Box>
      </Box>
    </>
  );
}
