import {
  Check,
  Close,
  Diamond,
  Forest,
  MeetingRoom,
  PriceCheck,
  Verified,
} from '@mui/icons-material';
import { Grid, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';

import { ParticipationChip, ThestralDataGrid } from 'components/thestral';

import {
  HTTP_METHOD,
  EMPTY,
  HOUSE_FILTER,
  PLOT_FILTER,
  EVENTS_URL,
  PAID_FILTER,
} from 'const';
import { Participant } from 'types';
import { useFetchFromBackend, useSnackbar } from 'func';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid';

type Props = {
  participants: Participant[];
  eventId: number;
  triggerRefetch: () => void;
};

export function Participations(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [participants, setParticipants] = useState<Participant[]>();

  const [activeChip, setActiveChip] = useState<string>();

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const  [colVisModel, setColVisModel] = useState({});

  useEffect(() => {
    if (participants != props.participants) {
      setParticipants(props.participants);
      setLoading(false);
      
    }
  }, [props.participants]);

  useEffect(() => {
    setColVisModel({
      email: !isSm,
      nickname: !isSm
    });
  }, [isSm]);

  const onStatusChange = (elem, type) => {
    setLoading(true);
    fetchFromBackend(`${EVENTS_URL}/${props.eventId}/${type}/${elem.id}`, {
      method: HTTP_METHOD.PUT,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        const i18nKey = type == 'approve' ? 'Approval' : 'Cancel';
        showSnackbar(
          `${t('Components.Admin.Events.' + i18nKey)} ${result}`,
          severity
        );
      })
      .catch((error) => console.error(error))
      .finally(() => {
        props.triggerRefetch();
        setLoading(false);
      });
  };

  const apiRef = useGridApiRef();
  const [houseFilterCount, setHouseFilterCount] = useState([]);
  const [plotFilterCount, setPlotFilterCount] = useState([]);
  const [paidFilterCount, setPaidFilterCount] = useState([]);

  const getFilteredRowsCount = useCallback(
    (filterModel) => {
      const { filteredRowsLookup } = apiRef.current.getFilterState(filterModel);
      return Object.keys(filteredRowsLookup).filter(
        (rowId) => filteredRowsLookup[rowId]
      ).length;
    },
    [apiRef]
  );

  useEffect(() => {
    if (participants?.length === 0) {
      return;
    }

    const houseCounts = HOUSE_FILTER.map(({ filterModel }) =>
      getFilteredRowsCount(filterModel)
    );
    const plotCounts = PLOT_FILTER.map(({ filterModel }) =>
      getFilteredRowsCount(filterModel)
    );
    const paidCounts = PAID_FILTER.map(({ filterModel }) =>
      getFilteredRowsCount(filterModel)
    );

    setHouseFilterCount(houseCounts);
    setPlotFilterCount(plotCounts);
    setPaidFilterCount(paidCounts);
  }, [apiRef, participants, getFilteredRowsCount]);

  const icon = (id: string): ReactNode => {
    switch (id) {
      case 'forest':
        return <Forest />;
      case 'artifacts':
        return <Diamond />;
      case 'rooms':
        return <MeetingRoom />;
      case 'paid':
        return <PriceCheck />;
      case 'registered':
        return <Check />;
    }

    return <></>;
  };

  const clickChip = (elem) => {
    if (elem.id === activeChip) {
      setActiveChip('');
    } else {
      setActiveChip(elem.id);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
          justifyContent: 'space-evenly',
        }}
      >
        {HOUSE_FILTER.map((data, index) => {
          const count = houseFilterCount[index];
          return (
            <ParticipationChip
              key={data.label}
              data={data}
              isActive={activeChip == data.id}
              count={count}
              apiRef={apiRef}
              onClick={() => clickChip(data)}
            />
          );
        })}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
          justifyContent: 'space-evenly',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '5px',
              justifyContent: 'space-evenly',
            }}
          >
            {PLOT_FILTER.map((data, index) => {
              const count = plotFilterCount[index];
              return (
                <ParticipationChip
                  key={data.id}
                  data={data}
                  icon={icon(data.id)}
                  isActive={activeChip == data.id}
                  count={count}
                  apiRef={apiRef}
                  onClick={() => clickChip(data)}
                />
              );
            })}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '5px',
              justifyContent: 'flex-end',
            }}
          >
            {PAID_FILTER.map((data, index) => {
              const count = paidFilterCount[index];
              return (
                <ParticipationChip
                  key={data.id}
                  data={data}
                  icon={icon(data.id)}
                  isActive={activeChip == data.id}
                  count={count}
                  apiRef={apiRef}
                  onClick={() => clickChip(data)}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ThestralDataGrid
          apiRef={apiRef}
          sx={{
            minWidth: '200px',
          }}
          rows={participants || EMPTY}
          columnVisibilityModel={colVisModel}
          columns={[
            { field: 'nickname', headerName: t('Generic.Nickname'), flex: 1 },
            {
              field: 'name',
              headerName: t('Generic.Name'),
              flex: 2,
              width: 500,
              valueGetter: (_, row) => (
                `${row.lastname}, ${row.firstname}`
              )
            },
            { field: 'email', headerName: t('Generic.Email'), flex: 1 },
            {
              field: 'house',
              headerName: t('Components.Characters.House'),
              flex: 1,
              valueGetter: (_, row) => {
                const house = t(`Enum.House.${row.house}`);
                return !row.house ? '' : (isSm ? house[0] : house);
              }
            },
            {
              field: 'plot',
              headerName: 'Plot',
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <IconButton color='primary'>
                  {params?.row.plot === 'ARTIFACTS' && (
                    <Tooltip title={t('Enum.Plot.ARTIFACTS')}>
                      <Diamond />
                    </Tooltip>
                  )}
                  {params?.row.plot === 'FOREST' && (
                    <Tooltip title={t('Enum.Plot.FOREST')}>
                      <Forest />
                    </Tooltip>
                  )}
                  {params?.row.plot === 'ROOMS' && (
                    <Tooltip title={t('Enum.Plot.ROOMS')}>
                      <MeetingRoom />
                    </Tooltip>
                  )}
                </IconButton>
              ),
            },
            {
              field: 'status',
              headerName: 'Status',
              flex: 0.7,
              renderCell: (params: GridRenderCellParams) => (
                <IconButton color='primary'>
                  {params?.row.status === 'REGISTERED' && (
                    <Tooltip title={t('Enum.ParticipationStatus.REGISTERED')}>
                      <Check />
                    </Tooltip>
                  )}
                  {params?.row.status === 'PAID' && (
                    <Tooltip title={t('Enum.ParticipationStatus.PAID')}>
                      <PriceCheck />
                    </Tooltip>
                  )}
                  {params?.row.status === 'CANCELLED' && (
                    <Tooltip title={t('Enum.ParticipationStatus.CANCELLED')}>
                      <Close />
                    </Tooltip>
                  )}
                </IconButton>
              ),
            },
            {
              field: 'actions',
              headerName: t('Generic.Actions'),
              flex: 0.7,
              headerAlign: 'right',
              align: 'right',

              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params?.row.status !== 'PAID' && (
                    <IconButton color='primary'>
                      <Tooltip title={`${t('Enum.ParticipationStatus.PAID')}?`}>
                        <Verified
                          onClick={() => onStatusChange(params?.row, 'approve')}
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                  {params?.row.status !== 'CANCELLED' && (
                    <IconButton color='primary'>
                      <Tooltip
                        title={t('Components.Admin.Events.Cancellation')}
                      >
                        <Close
                          onClick={() => onStatusChange(params?.row, 'cancel')}
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                </>
              ),
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'lastname', sort: 'asc' }],
            },
          }}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}
