import { ReactNode } from 'react';
import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';

import { PastCons, RulesTab, RulesTabContext } from 'components/thestral';

import { RuleComponent } from 'types';

export function World(): ReactNode {
  const auth = useAuth();
  const title = t('Components.Rules.WorldBuilding.Title');
  const breadcrumbs = [
    {href: '/', title: t('Generic.Start')},
    {href: '/rules', title: t('Components.Rules.Title')}
  ];

  const items = [
    {name: t('Components.Rules.WorldBuilding.Setting'), component: <RulesTab slug='setting' ruleType='WorldBuilding' publicLoc />},
  ];

  const authItems = [
    {name: t('Components.Rules.WorldBuilding.Cons'), component: <PastCons />},
    {name: t('Components.Rules.WorldBuilding.Hogwarts'), component: <RulesTab slug='hogwarts' ruleType='WorldBuilding' />},
    {name: t('Components.Rules.WorldBuilding.Clubs'), component: <RulesTab slug='clubs' ruleType='WorldBuilding' />, disabled: true},
    {name: t('Components.Rules.WorldBuilding.Dailyprophet'), component: <RulesTab slug='dailyprophet' ruleType='WorldBuilding' />, disabled: true},
    {name: t('Components.Rules.WorldBuilding.Shops'), component: <RulesTab slug='shops' ruleType='WorldBuilding' />, disabled: true},
    {name: t('Components.Rules.WorldBuilding.Politics'), component: <RulesTab slug='politics' ruleType='WorldBuilding' />, disabled: true},
    {name: t('Components.Rules.WorldBuilding.Quidditchwb'), component: <RulesTab slug='quidditchwb' ruleType='WorldBuilding' />, disabled: true}
  ];

  return (
    <RulesTabContext
      title={title}
      items={auth.isAuthenticated ? items.concat(authItems) as RuleComponent[] : items as RuleComponent[]}
      breadcrumbs={auth.isAuthenticated ? breadcrumbs : []}
    />
  );
}