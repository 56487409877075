import { useContext, ReactNode } from 'react';
import { SelectChangeEvent, Box, Grid } from '@mui/material';

import { t } from 'i18next';

import { SelectField } from 'components/ui';

import { useFetch } from 'func';
import { CharacterContext } from 'contexts';
import { THESTRAL_URL } from 'const';

type Props = {
  readonly: boolean
}

export function FounderHouse(props: Readonly<Props>) : ReactNode {
  const {char, setChar} = useContext(CharacterContext);

  const houses = useFetch<string[]>(`${THESTRAL_URL}/houses`);

  const changeSelectField = (event: SelectChangeEvent, key: string) => {
    const value = event.target.value;
    const newChar = {...char, [key]: value};
    setChar(newChar);
  };

  return (
    <Grid item xs={12} >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'flex-end' 
      }}>
        {houses && 
        <SelectField
            id='house'
            readonly={props.readonly}
            label={t('Components.Characters.House')}
            value={[char?.house]}
            list={houses.data?.filter((house) => house != 'NOHOUSE')}
            i18nKey='Enum.House'
            onChange={(event) => changeSelectField(event, 'house')}
        />
        }
      </Box>
    </Grid>
  );
}