import { useState, ReactNode } from 'react';
import { Card, CardContent } from '@mui/material';
import { CloseConfirm } from '../CloseConfirm';
import { Dialog } from 'components/ui/Dialog';

type Props = {
  dialogTitle: string,
  dialogActions: ReactNode
  dialogContent: ReactNode
  closeConfirm?: boolean,
  closeConfirmSave?: () => void,
  closeConfirmCancel?: () => void
  closeConfirmCondition?: boolean
  cardContent: ReactNode
  cardMedia: ReactNode
}

export function CardItem(props: Readonly<Props>): ReactNode {
  const [closeConfirm, setCloseConfirm] = useState(false);

  const [open, setOpen] = useState(false);

  function closeDialog() {
    if (props.closeConfirmCondition) {
      setCloseConfirm(true);
    } else {
      setOpen(false);
    }
  }

  function closeAll() {
      setCloseConfirm(false);  
      setOpen(false);
  }

  function save(){
    props.closeConfirmSave();
    closeAll();
  }

  function cancel() {
    props.closeConfirmCancel();
    closeAll();
  }

  return (
    <>
      {props.closeConfirm &&
        <CloseConfirm 
          open={closeConfirm}
          save={save}
          cancel={cancel}
        />
      }

      <Card 
        elevation={0} 
        sx={{ 
          backgroundColor: 'unset', 
          paddingTop: '20px',
          transition: '0.3s cubic-bezier(0.25, 0.8, 0.5, 1)',
          '&:hover': {
            transform: 'scale(1.1)'
          }
        }} 
        onClick={() => setOpen(true)}
      >
        {props.cardMedia}
        <CardContent>
          {props.cardContent}
        </CardContent>
      </Card>

      { open && <Dialog 
        sx={{
          '& .MuiPaper-root': {
            bgcolor: '#1e1e1e',
            marginBottom: '0'
          }
        }}
        open={open}
        onClose={closeDialog}  
        title={props.dialogTitle}
        content={props.dialogContent}
        actions={props.dialogActions}
      />
      }
    </>
  );
}