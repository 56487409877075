import { useState, ReactNode, useEffect } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { t } from 'i18next';

import { Button, TextField } from 'components/ui';

import { HTTP_METHOD, OWLPOST_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';

import { Event, Owlpost } from 'types';
import { Trans } from 'react-i18next';

type Props = {
  event: Event
}

export function OwlpostNote(props: Readonly<Props>) : ReactNode {
  const { data, triggerRefetch } = useFetch<Owlpost>(`${OWLPOST_URL}/by-event/${props.event?.id}`);

  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();
  
  const [note, setNote] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setLoading(false);
      setNote(data?.note);
      if (data?.userSignedUp) {
        setShow(true);
      }
    }
  }, [data]);

  const updateEnrollment = (type: string) => {
    setLoading(true);
    const body = JSON.stringify({'note': note});
    fetchFromBackend(`${OWLPOST_URL}/${data?.id}/${type}`, {method: HTTP_METHOD.PUT, body})
      .then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          if (response.ok) {
            triggerRefetch();
          }

          const i18nKey = (type === 'signup') ? 'SignUp.Post' : 'Update.Post';
          showSnackbar(`${t('Components.Events.Owlpost.'+i18nKey)} ${result}`, severity);
      }).finally(() => setLoading(false));
  };

  const content = () => {
    if (data?.registrationOpen) {
      return (<Grid container spacing={2}>
        {data?.userSignedUp &&
          <Grid item xs={12} justifyContent='center' display='flex'>
            <Typography variant='h6' sx={{ color: '#9e9e9e', textAlign: 'center' }}>
              <Trans i18nKey='Components.Events.Owlpost.NoteLabel' />
            </Typography>
          </Grid>
        }
        <Grid item xs={12}>
          {!show ? 
            <Button color='warning' onClick={() => setShow(true)} text={t('Components.Events.Owlpost.Open')} />
          :
          <TextField
              defaultValue={note}
              multiline
              rows={5}
              onChange={(event) => setNote(event.target.value)}
          />
          }
        </Grid>
        {show && 
          <Grid item xs={12} display='flex' justifyContent='center'> 
            {!data?.userSignedUp ?
              <Button color='warning' onClick={() => updateEnrollment('signup')} text={t('Components.Events.Owlpost.SignUp.Title')} />
              :
              <Button color='warning' onClick={() => updateEnrollment('edit')} text={t('Components.Events.Owlpost.Update.Title')} />
            }
          </Grid>
        }
      </Grid>);
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} justifyContent='center' display='flex'>
            <Typography variant='h6' sx={{ color: '#9e9e9e', textAlign: 'center' }}>
              <Trans i18nKey='Components.Admin.Events.Owlpost.RegistrationNotOpen' />
            </Typography>
          </Grid>
          
        </Grid>
      );
    }
  };

  return (
    <>
      {loading ? 
        <CircularProgress />
      :
        content()
      }
    </>
  );
}