import { ReactNode } from 'react';
import { Box,Table,TableContainer } from '@mui/material';

type Props = {
  headers: ReactNode,
  items: ReactNode,
}

export function RulesTable(props: Readonly<Props>): ReactNode {
  return (
    <TableContainer 
      component={Box}
      sx={{
        '& .MuiTableCell-root' : {
          color: '#ffffff',
          fontFamily: 'Neuton',
          fontSize: '16px',
          borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
        },
        '& .MuiTableCell-head' : {
          color: '#d3d3d3',
          fontFamily: 'Neuton',
          fontWeight: 'bold'
        }
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label='table'>
        {props.headers}
        {props.items}
      </Table>
    </TableContainer>
  );
}