import { AppBar, Toolbar } from '@mui/material';

import { LogoutMenu } from './LogoutMenu';
import { BurgerMenu } from './BurgerMenu';

export function Navigation() {
  return (
    <AppBar position='fixed' color='secondary'>
      <Toolbar
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <BurgerMenu />
        <LogoutMenu />
      </Toolbar>
    </AppBar>
  );
}
