import { AriaRole, ReactNode } from 'react';
import { Button as MuiButton, SxProps }  from '@mui/material';

type Props = {
  className?: string
  color?: 'primary' | 'success' | 'error' | 'secondary' | 'warning',
  component?: 'label'
  disabled?: boolean
  fullWidth?: boolean
  href?: string
  onClick?: () => void
  role?: AriaRole
  startIcon?: ReactNode
  sx?: SxProps
  tabIndex?: number
  text: ReactNode,
  variant?: 'outlined'
}

export function Button(props: Readonly<Props>): ReactNode {
  return (
      <MuiButton 
        color={props.color ?? 'info'}
        component={props.component}
        className={props.className}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        href={props.href}
        onClick={props.onClick}
        role={props.role}
        startIcon={props.startIcon}   
        sx={{ ...props.sx, letterSpacing: '0.0892857143em' }} 
        tabIndex={props.tabIndex}
        variant={props.variant ?? 'contained'}
      >
          {props.text}
      </MuiButton>
  );
}