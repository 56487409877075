import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type Props = {
  title: string;
  color?: string;
  fontWeight?: string;
};

export function RulesBlockSubtitle(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='h1'
      component='p'
      id='rules-block-subtitle'
      sx={{
        fontSize: '1.3rem',
        fontWeight: props.fontWeight || '700',
        textAlign: 'left',
        lineHeight: '50px',
        color: props.color || 'primary',
        fontFamily: 'Neuton',
        letterSpacing: '0.0071428571em',
      }}
    >
      {props.title}
    </Typography>
  );
}
