import { ReactNode } from 'react';
import { TableCell, TableBody, TableRow } from '@mui/material';

type Props = {
  rows: string[][]
}

export function RulesTableBody(props: Readonly<Props>): ReactNode {
  return (
    <TableBody>
    {
      props.rows.map((row, i) => {
        return (
          <TableRow key={'row-'+i}>
            {
              row.map((cell, j) => {
                return (
                  <TableCell key={'cell-'+j}>
                    {cell}
                  </TableCell>
                );
              })
            }
          </TableRow>
        );
      })
    }
    </TableBody>
  );
}