import { ReactNode } from 'react';
import { Resource } from'@opentelemetry/resources';
import { WebTracerProvider, SimpleSpanProcessor, ConsoleSpanExporter } from'@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter }  from'@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from'@opentelemetry/instrumentation';
import { FetchInstrumentation } from'@opentelemetry/instrumentation-fetch';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { BACKEND_URL, DEBUG } from 'const';

if(!DEBUG) { // Disable Tracing in Local Development
  const collectorExporter = new OTLPTraceExporter({
    url: 'https://otel.gothax.net/v1/traces',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'X-CSRF': '1'
    }
  });

  const provider = new WebTracerProvider({
    resource: new Resource({
      'service.name': 'frontend'
    })
  });

  const fetchInstrumentation = new FetchInstrumentation({
    propagateTraceHeaderCorsUrls: [
      new RegExp(BACKEND_URL + '/.')
    ],
    clearTimingResources: true,
  });
  fetchInstrumentation.setTracerProvider(provider);

  const consoleExporter = new ConsoleSpanExporter();

  provider.addSpanProcessor(new SimpleSpanProcessor(consoleExporter));
  provider.addSpanProcessor(new SimpleSpanProcessor(collectorExporter));
  provider.register({
    propagator: new W3CTraceContextPropagator()
  });

  registerInstrumentations({
    instrumentations: [
      fetchInstrumentation
    ],
    tracerProvider: provider
  });
}

type Props = {
  children: ReactNode
};

export function TraceProvider (props: Readonly<Props>) {
  return (
    props.children
  );
}