import { Chip, CircularProgress, Paper } from '@mui/material';
import { Button, Dialog, TextField } from 'components/ui';
import { useFetchFromBackend, useSnackbar } from 'func';
import { t } from 'i18next';
import { useState } from 'react';
import { ThestralDataGrid } from '../ThestralDataGrid';
import { Check, Close } from '@mui/icons-material';
import { HTTP_METHOD, REGISTRATION_URL } from 'const';

type Props = {
  open: boolean;
  title: string;
  onClose: () => void;
};

export function InviteDialog(props: Readonly<Props>) {
  const [emails, setEmails] = useState<string[]>([]);
  const [responses, setResponses] = useState([]);
  const [value, setValue] = useState<string>();
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (elem) => {
    const value = elem.target.value;
    setValue(value);
    if (value.slice(-1) == ',') {
      addEmail();
    }
  };

  const addEmail = () => {
    const email = value.endsWith(',') ? value.slice(0, -1) : value;
    if (!emails.includes(email)) {
      setEmails([...emails, email]);
      setValue('');
    }
  };

  const onKeyDown = (elem) => {
    if (elem.key === 'Enter') {
      addEmail();
    }
  };

  const handleDelete = (email) => {
    setEmails(() => emails.filter((e) => e !== email));
  };

  const validate = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSave = () => {
    setLoading(true);
    const valids = emails.filter((e) => validate(e));
    const responses = valids.reduce((acc, email) => {
      acc.push({ id: email, status: 'pending' });
      return acc;
    }, []);
    setResponses(responses);
    const promises = valids.map((email) =>
      fetchFromBackend(`${REGISTRATION_URL}/invite`, {
        body: JSON.stringify({ email: email }),
        method: HTTP_METHOD.POST,
      }).then((response) =>
        setResponses((responses) =>
          responses.map((item) =>
            item.id === email ? { ...item, status: response.statusText } : item
          )
        )
      )
    );

    Promise.allSettled(promises).then(() => {
      showSnackbar(`${'Components.Admin.Users.EmailSent'}`, 'success');
      setLoading(false);
    });
  };

  const showProgress = (elem) => {
    switch (elem.status) {
      case 'pending':
        return <CircularProgress color='primary' />;
      case 'OK':
        return <Check color='success' />;
      default:
        return <Close color='error' />;
    }
  };

  const onClose = () => {
    setResponses(null);
    setValue('');
    setEmails([]);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      title={props.title}
      onClose={onClose}
      content={
        <>
          {responses && (
            <ThestralDataGrid
              rows={responses}
              hideSearch
              hideFooter
              hideFooterPagination
              columns={[
                { field: 'id', headerName: t('Generic.Email'), flex: 3 },
                {
                  field: 'status',
                  flex: 1,
                  headerName: t('Generic.Pending'),
                  renderCell: (params) => showProgress(params.row),
                },
              ]}
              sx={{
                width: '100%',
                marginBottom: '50px',
              }}
            />
          )}
          <Paper
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              border: '1px solid #aeaeae',
              bordeRadius: '10px',
              padding: '10px',
              backgroundColor: 'transparent',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {emails?.map((email) => (
              <>
                {validate(email) ? (
                  <Chip
                    color='secondary'
                    key={email}
                    label={email}
                    onDelete={() => handleDelete(email)}
                  />
                ) : (
                  <Chip
                    color='error'
                    key={email}
                    label={email}
                    onDelete={() => handleDelete(email)}
                  />
                )}
              </>
            ))}
          </Paper>
          <TextField
            label={t('Generic.Emails')}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            value={value}
          />
        </>
      }
      actions={
        <Button
          color='success'
          onClick={onSave}
          text={t('Components.Admin.Users.SendInvites')}
          disabled={emails.length == 0 || loading}
        />
      }
    />
  );
}
