import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { t } from 'i18next';

type Props = {
    id: string
}

export function CardSectionTitle(props: Readonly<Props>) : ReactNode {
  return (
    <Typography 
    variant='caption' 
    component='p' 
    sx={{  
        color: 'primary.main',
        fontWeight: 700,
        margin: '0 0 20px 0'
    }}>
      { t(props.id) }
    </Typography>
  );
}
