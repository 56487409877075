import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { RulesListItemLink } from '../RulesListItemLink';
import { CustomLink } from 'types';

type Props = {
  items: CustomLink[]
}

export function RulesBlockLinks(props: Readonly<Props>): ReactNode {
  return (
    <Box>
      {
        props.items.map((item) =>
          <RulesListItemLink key={item.title} item={item} />
        )
      }
    </Box>
  );
}