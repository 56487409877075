import { ReactNode, useEffect, useState } from 'react';
import { GridRowParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import { Box, SxProps } from '@mui/material';

import {
  ThestralDataGrid,
  ThestralDataGridHeadline,
} from 'components/thestral';

import { CharacterRevision, HouseType } from 'types';
import { CHARACTERS_URL, EMPTY } from 'const';
import { useFetch } from 'func';

type Props = {
  house: HouseType;
  onRowClick: (elem: GridRowParams) => void;
  sx: SxProps;
  inactive?: boolean;
};

export function HouseList(props: Readonly<Props>): ReactNode {
  const slug = props.inactive ? '/inactive' : '';
  const { data, loading } = useFetch<CharacterRevision[]>(
    `${CHARACTERS_URL}${slug}?house=${props.house}`
  );
  const [chars, setChars] = useState<CharacterRevision[]>();

  useEffect(() => {
    if (data) {
      setChars(data);
    }
  }, [data]);

  const onRowClick = (elem) => {
    props.onRowClick(elem.row);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ThestralDataGridHeadline
        headline={
          props.house.charAt(0) + props.house.substring(1).toLowerCase()
        }
      />
      <ThestralDataGrid
        rows={chars?.map((char) => char.playerCharacter) || EMPTY}
        onRowClick={(elem) => onRowClick(elem)}
        sx={props.sx}
        columns={[
          {
            field: 'name',
            headerName: t('Components.PlayerCharacters.Name'),

            flex: 2,
            sortable: true,
          },
          {
            field: 'ageGroup',
            headerName: t('Components.PlayerCharacters.AgeGroup'),

            flex: 1,
            sortable: true,
            valueGetter: (_, row) =>
              chars && row.ageGroup
                ? t('Enum.AgeGroup.' + `${row.ageGroup}`)
                : '',
          },
          {
            field: 'playerName',
            headerName: t('Components.PlayerCharacters.PlayerName'),
            sortable: true,
            flex: 1,

            align: 'right',
            headerAlign: 'right',
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 15, 20, 25]}
        loading={loading}
      />
    </Box>
  );
}
