import { Box, CircularProgress } from '@mui/material';
import { t } from 'i18next';

import { ThestralDataGrid } from 'components/thestral';

import { EMPTY } from 'const';
import { Signup } from 'types';

type Props = {
  signups: Signup[]
  loading: boolean
  onRowClick?: (elem) => void
}

export function SignupApprovalGrid(props: Readonly<Props>) {
  const signups = props.signups;

  return (
    <Box>
    {props.loading ? 
        <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
          <CircularProgress />
        </Box> 
      :
        <ThestralDataGrid
          rows={signups || EMPTY}
          sx={{
            display: signups?.length == 0 ? 'none' : 'block',
            width: '100%'
          }}
          onRowClick={props.onRowClick}
          columns={[
            {field: 'pronouns', headerName: t('Generic.Pronouns'), sortable: true, flex: 1},
            {field: 'lastname', headerName: t('Components.Profile.PersonalData.LegalData.LastName'), sortable: true, flex: 1},
            {field: 'firstname', headerName: t('Components.Profile.PersonalData.LegalData.FirstName'), sortable: true, flex: 1},
            {field: 'nickname', headerName: t('Generic.Nickname'), sortable: true, flex: 1},
            {field: 'email', headerName: t('Generic.Email'), sortable: true, flex: 1}
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 }
            }
          }}
          hideFooter
          pageSizeOptions={[5, 10]}
          loading={props.loading}
        />
    }
    </Box>
  );
}
