export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const USERS_URL = `${BACKEND_URL}/users`;
export const OWLPOST_URL = `${BACKEND_URL}/owlposts`;
export const CONTENT_URL = `${BACKEND_URL}/contents`;
export const EVENTS_URL = `${BACKEND_URL}/events`;
export const CHARACTERS_URL = `${BACKEND_URL}/characters`;

export const PEOPLE_URL = `${CHARACTERS_URL}/people`;
export const NPCS_URL = `${CHARACTERS_URL}/npcs`;
export const THESTRAL_URL = `${CHARACTERS_URL}/thestral`;
export const CLUBS_URL = `${CHARACTERS_URL}/clubs`;
export const DRAPERIES_URL = `${CHARACTERS_URL}/draperies`;

export const WAITINGLIST_URL = `${USERS_URL}/waiting-list`;
export const SIGNUP_URL = `${USERS_URL}/signups`;
export const PROFILES_URL = `${USERS_URL}/profiles`;
export const ROLES_URL = `${USERS_URL}/roles`;
export const REGISTRATION_URL = `${USERS_URL}/registration`;

export const DEBUG = process.env.REACT_APP_DEBUG;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

export enum IconColor {
  X = '#ff0000',
  Check = '#00ff00',
  Circle = '#eab354'
}

export const EMPTY = [{'id': 0}];
export const SNACKBAR_AUTO_HIDE = 2000;
export const TEXT_FIELD_BIG = 4000;
export const SLIDE_DIRECTION = 'up';

export const HOUSE_FILTER = [
  {
    id: '',
    label: 'Generic.All',
    avatarColor: '#616161',
    color: '#555555',
    filterModel: {
      items: [
      ]
    }
  },
  {
    id: 'gryffindor',
    label: 'Enum.House.GRYFFINDOR',
    avatarColor: '#C62828',
    color: '#b71c1c',
    filterModel: {
      items: [
        {field: 'house', operator: 'equals', value: 'Gryffindor'}
      ]
    }
  },
  {
    id: 'ravenclaw',
    label: 'Enum.House.RAVENCLAW',
    avatarColor: '#30484a',
    color: '#1a3134',
    filterModel: {
      items: [
        {field: 'house', operator: 'equals', value: 'Ravenclaw'}
      ]
    }
  },
  {
    id: 'hufflepuff',
    label: 'Enum.House.HUFFLEPUFF',
    avatarColor: '#926600',
    color: '#754e00',
    filterModel: {
      items: [
        {field: 'house', operator: 'equals', value: 'Hufflepuff'}
      ]
    }
  },
  {
    id: 'slytherin',
    label: 'Enum.House.SLYTHERIN',
    avatarColor: '#2E7D32',
    color: '#1B5E20',
    filterModel: {
      items: [
        {field: 'house', operator: 'equals', value: 'Slytherin'}
      ]
    }
  },
];

export const PLOT_FILTER = [
  {
    id: 'forest',
    avatarColor: '#616161',
    color: '#555555',
    textColor: '#eab354',
    filterModel: {
      items: [
        {field: 'plot', operator: 'equals', value: 'FOREST'}
      ]
    }
  },
  {
    id: 'artifacts',
    avatarColor: '#616161',
    color: '#555555',
    textColor: '#eab354',
    filterModel: {
      items: [
        {field: 'plot', operator: 'equals', value: 'ARTIFACTS'}
      ]
    }
  },
  {
    id: 'rooms',
    avatarColor: '#616161',
    color: '#555555',
    textColor: '#eab354',
    filterModel: {
      items: [
        {field: 'plot', operator: 'equals', value: 'ROOMS'}
      ]
    }
  }
];

export const PAID_FILTER = [
  {
    id: 'paid',
    avatarColor: '#616161',
    color: '#555555',
    textColor: '#eab354',
    filterModel: {
      items: [
        {field: 'status', operator: 'equals', value: 'PAID'}
      ]
    }
  },
  {
    id: 'registered',
    avatarColor: '#616161',
    color: '#555555',
    textColor: '#eab354',
    filterModel: {
      items: [
        {field: 'status', operator: 'equals', value: 'REGISTERED'}
      ]
    }
  }
];